import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalResult, DwModalService, DwModalSize } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaDataItemNames, PlanOfActionEntity, PlanOfActionSectionEntity } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../../plan-of-action/components/generate-sections-modal/generate-sections-modal.component";
import { map } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../../plan-of-action/components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";

@DwAction({
    keyName: 'generate-quesitons-action',
    display: 'Generate Questions',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfActionSection],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        buttonClass: 'btn btn-warning mr-1 mt-1',
        buttonText: 'Add Acs Codes',
        iconClass: 'fa fa-tasks-alt'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: '',
    }
})
@Injectable()
export class GenerateQuestionsActionService implements DwActionHandler {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private planofActionGeneratorService: PlanOfActionGeneratorService,
        private dwModalService: DwModalService

    ) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        const result = new DwActionResult();

        const currentPlanOfActionSection: PlanOfActionSectionEntity = actionState.data;

        const planOfAction = actionState.formApi.getFormData<PlanOfActionEntity>();

        const section = planOfAction.PlanOfActionSection.find(x => x.PlanOfActionSectionId === currentPlanOfActionSection.PlanOfActionSectionId);

        return this.generateQuestions(actionState, planOfAction, section);
    }


    generateQuestions(actionState: DwMdFormActionState, planOfAction: PlanOfActionEntity, planOfActionSection: PlanOfActionSectionEntity): Observable<DwActionResult> {

        return this.showAcsCodeSelectionModal(planOfAction, planOfActionSection)
            .pipe(map(modalResult => {
                const result = new DwActionResult(); 

                if (result.cancelled) {
                    result.cancelled = true;

                    return result;
                }

                if (modalResult.data) {
                    this.planofActionGeneratorService.generateQuestions(planOfActionSection, planOfActionSection.PlanOfActionSectionQuestion,modalResult.data.GenerateCodesOptions);
                    
                    // Plan of action's reference is updated so we can just use that
                    actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction); 
                    actionState.formApi.formGroup.patchValue(planOfAction);
                    actionState.formApi.setFormData(planOfAction);
                }

                return result;


            }));
    }
    
    public showAcsCodeSelectionModal(planOfAction: PlanOfActionEntity, planOfActionSection: PlanOfActionSectionEntity): Observable<DwModalResult<AcsCodeSelectionModalResult>> {

        let modalConfig = new DwModalConfig<AcsCodeSelectionModalOptions>();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.large;

        modalConfig.component = GenerateQuestionsModalComponent;

        modalConfig.title = 'Add AcsCodes';

        modalConfig.data = {
            acsTestId: planOfAction.AcsTestId,
            acsAreaOfOperationId: planOfActionSection.AcsAreaOfOperationId,
            acsTaskId: planOfActionSection.AcsAreaOfOperationTaskId,
            // planOfAction: planOfAction,
            // planOfActionSection: planOfActionSection,
            allowQuestionSelect: planOfAction.OptionsShowQuestionForAcsCode,
            acsCodeStatuses: this.planOfActionRepositoryService.getAcsCodeStatus(planOfAction),
        };

        return this.dwModalService.showModal<AcsCodeSelectionModalOptions,AcsCodeSelectionModalResult>(modalConfig)
            .pipe(map(modalResult => {

                return modalResult;
              
            }));
    }
}