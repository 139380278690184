// tslint:disable: max-line-length
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AgGridModule } from 'ag-grid-angular';
import { OrderListModule } from 'primeng/orderlist';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
//import {FullCalendarModule} from 'primeng/fullcalendar';
import { FullCalendarModule } from '@fullcalendar/angular';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppRoutingModule } from './app-routing.module';  

import { DevwareCoreModule,
  DevwareOrmModule,
  DevwareUIModule,
  DevwareAGGridModule, 
  DevwareUILayoutModule,
  DevwareUIMetaDataModule,
  DevwareAdminModule,
  DevwareUiWizardModule, 
  DevwareMediaModule} from '@devwareapps/devware-cap';

import { AppDevwareConfigProviderService } from './app-devware-config-provider.service';


import { AppComponent } from './pages/app/app.component';
import { HomeComponent } from './features/shared/pages/home/home.component';
import { ExaminerBookingCalendarComponent } from './features/examiner/components/examiner-booking-calendar/examiner-booking-calendar.component';
import { ExaminerBookingSearchResultsComponent } from './features/booking/components/examiner-booking-search-results/examiner-booking-search-results.component';
import { CurrentStudentContextService } from './features/shared/contexts/current-student-context/current-student-context.service';
import { ExaminerBookingSearchResultComponent } from './features/booking/components/examiner-booking-search-result/examiner-booking-search-result.component';
import { CurrentPilotExaminerContextService } from './features/shared/contexts/current-pilot-examiner/current-pilot-examiner.service';
import { ScheduleSelectorComponent } from './features/booking/components/schedule-selector/schedule-selector.component';
import { ScheduleDetailComponent } from './features/booking/components/schedule-detail/schedule-detail.component';
import { GoogleCalendarConsentComponent } from './features/calendar/components/google-calendar-consent/google-calendar-consent.component';
import { CalendarWeekdayComponent } from './features/calendar/components/calendar-weekday/calendar-weekday.component';

import { DayPilotModule } from 'daypilot-pro-angular';

import { NgbDatepickerModule, NgbTimepickerModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { TreeModule } from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ExaminerCheckRideUtilComponent } from './features/examiner/components/examiner-check-ride-util/examiner-check-ride-util.component';
import { PilexosHomeComponent } from './features/homepage/components/home-page/home-page.component';
import { AnnonymousHomeViewComponent } from './features/homepage/components/annonymous-home-view/annonymous-home-view.component';
import { AdditionalContentComponent } from './features/homepage/components/additional-content/additional-content.component';
import { MenuItemCardComponent } from './features/homepage/components/menu-item-card/menu-item-card.component';
import { UserRegistrationComponent } from './features/homepage/components/user-registration/user-registration.component';
import { ExaminerDashboardComponent } from './features/examiner/components/examiner-dashboard/examiner-dashboard.component';
import { AuthenticatedHomeViewComponent } from './features/homepage/components/authenticated-home-view/authenticated-home-view.component';
import { DwTaskContainerComponent } from './features/z-devware/tasks/components/dw-task-container/dw-task-container.component';


import {ChartModule} from 'primeng/chart';
import { ExaminerFinancialSnapshotComponent } from './features/examiner/components/examiner-financial-snapshot/examiner-financial-snapshot.component';
import { DwMiniTileComponent } from './features/z-devware/visualizations/components/dw-mini-tile/dw-mini-tile.component';
import { ApplicantDashboardComponent } from './features/applicant/components/applicant-dashboard/applicant-dashboard.component';
import { ExaminerLocationUtilComponent } from './features/examiner/components/examiner-location-util/examiner-location-util.component';
import { ExaminerPublicProfileComponent } from './features/examiner/components/examiner-public-profile/examiner-public-profile.component';
import { UsreAvatarComponent } from './features/shared/components/usre-avatar/usre-avatar.component';
import { PaymentInfoComponent } from './features/payments/components/payment-info/payment-info.component';
import { PaymentModalComponent } from './features/payments/components/payment-modal/payment-modal.component';
import { CheckridePaymentUtilComponent } from './features/payments/components/checkride-payment-util/checkride-payment-util.component';
import { ExaminerBookingAssignedComponent } from './features/booking/components/examiner-booking-assigned/examiner-booking-assigned.component';
import { DateTimeDisplayControlComponent } from './features/shared/components/date-time-display-control/date-time-display-control.component';
import { DurationDisplayControlComponent } from './features/shared/components/duration-display-control/duration-display-control.component';
import { CheckridePaymentRefundActionService } from './features/payments/actions/checkride-payment-refund-action.service';
import { EmailDispalyComponent } from './features/shared/components/email-dispaly/email-dispaly.component';
import { DurationControlComponent } from './features/shared/components/duration-control/duration-control.component';
import { DwChartTileComponent } from './features/z-devware/visualizations/components/dw-chart-tile/dw-chart-tile.component';
import { FinancialDashboardComponent } from './features/financials/components/financial-dashboard/financial-dashboard.component';
import { AdminFinancialsComponent } from './features/financials/components/admin-financials/admin-financials.component';
import { GridReturnToFinancialsActionService } from './features/financials/grid-actions/grid-return-to-financials/grid-return-to-financials-action.service';
import { FinancialDashboardFiltersComponent } from './features/financials/components/financial-dashboard-filters/financial-dashboard-filters.component';
import { ExaminerFinancialsComponent } from './features/financials/components/examiner-financials/examiner-financials.component';
import { ExaminerCalendarCheckComponent } from './features/examiner/components/examiner-calendar-check-util/examiner-calendar-check.component';
import { CheckridePaymentUtilExaminerComponent } from './features/payments/components/checkride-payment-util-examiner/checkride-payment-util-examiner.component';
import { ApplicantCheckRideUtilComponent } from './features/applicant/components/applicant-check-ride-util/applicant-check-ride-util.component';
import { RebookApplicantCheckrideActionService } from './features/booking/actions/rebook-applicant-checkride/rebook-applicant-checkride-action.service';
import { RebookExaminerCheckrideActionService } from './features/booking/actions/rebook-examiner-checkride/rebook-examiner-checkride-action.service';
import { AcceptProposedDateActionService } from './features/booking/actions/accept-proposed-date/accept-proposed-date-action.service';
import { QuestionEditControlComponent } from './features/plan-of-action/components/question-edit-control/question-edit-control.component';
import { GenerateSectionsModalComponent } from './features/plan-of-action/components/generate-sections-modal/generate-sections-modal.component';
import { GenerateSectionsActionService } from './features/plan-of-action/actions/generate-sections-action.service';
import { GenerateQuestionsActionService } from './features/plan-of-action/actions/generate-questions-action.service';
import { PlanOfActionSectionsComponent } from './features/plan-of-action/components/plan-of-action-sections/plan-of-action-sections.component';
import { SelectSectionsComponent } from './features/plan-of-action/components/select-sections/select-sections.component';
import { SelectAcsCodesComponent } from './features/plan-of-action/components/select-acs-codes/select-acs-codes.component';
import { GenerateQuestionsModalComponent } from './features/plan-of-action/components/generate-questions-modal/generate-questions-modal.component';
import { AddRequiredAcsCodesActionService } from './features/plan-of-action/actions/add-required-acs-codes-action.service';
import { RequiredAcsCodesGridComponent } from './features/plan-of-action/components/required-acs-codes-grid/required-acs-codes-grid.component';
import { PlanOfActionSectionComponent } from './features/plan-of-action/components/plan-of-action-section/plan-of-action-section.component';
import { PlanOfActionQuestionComponent } from './features/plan-of-action/components/plan-of-action-question/plan-of-action-question.component';
import { PlanOfActionDataSourceService } from './features/plan-of-action/data-source/plan-of-action-data-source.service';
import { PlanOfActionExecuteComponent } from './features/plan-of-action/components/plan-of-action-execute/plan-of-action-execute.component';
import { PlanOfActionExecuteSectionComponent } from './features/plan-of-action/components/plan-of-action-execute-section/plan-of-action-execute-section.component';
import { PlanOfActionExecuteQuestionComponent } from './features/plan-of-action/components/plan-of-action-execute-question/plan-of-action-execute-question.component';
import { PlanOfActionSectionEditComponent } from './features/plan-of-action/components/plan-of-action-section-edit/plan-of-action-section-edit.component';
import { SelectAcsCodesModalComponent } from './features/plan-of-action/components/select-acs-codes-modal/select-acs-codes-modal.component';
import { CheckrideFormDataSourceService } from './features/booking/data-sources/check-ride-from-data-source.service';
import { CheckridePlanOfActionUtilComponent } from './features/plan-of-action/components/checkride-plan-of-action-util/checkride-plan-of-action-util.component';
import { CheckrideCreatePOAActionService } from './features/plan-of-action/actions/checkride-create-poa-action.service';
//import { ExecutePOACompleteActionService } from './features/plan-of-action/actions/execute-poa-complete-checkride.service';
import { ACSWrittenDataContextService } from './features/plan-of-action/contexts/acs-written-data-context.service';
import { CheckrideSaveAndGotoPOAActionService } from './features/plan-of-action/actions/checkride-save-and-open-poa-action.service';
import { POASaveAndExecuteActionService } from './features/plan-of-action/actions/poa-save-and-execute-action.service';
import { POACompleteCheckrideActionService } from './features/plan-of-action/actions/poa-complete-checkride-action.service';
import { POAStartCompleteCheckrideActionService } from './features/plan-of-action/actions/poa-start-complete-checkride-action.service';
import { AddMissedAcsCodesActionService } from './features/plan-of-action/actions/add-missed-acs-codes-action.service';
import { PoaPrintOptionsModalComponent } from './features/plan-of-action/components/poa-print-options-modal/poa-print-options-modal.component';
import { POAPrintActionService } from './features/plan-of-action/actions/poa-print-action.service';
import { AcsCodeLookupDataSourceService } from './features/plan-of-action/data-source/acs-code-lookup-data-source.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ExaminerBookingCalendarComponent,
    ExaminerBookingSearchResultsComponent,
    ExaminerBookingSearchResultComponent,
    ScheduleSelectorComponent,
    ScheduleDetailComponent,
    GoogleCalendarConsentComponent,
    CalendarWeekdayComponent,
    ExaminerCheckRideUtilComponent,
    PilexosHomeComponent,
    AnnonymousHomeViewComponent,
    AuthenticatedHomeViewComponent,
    AdditionalContentComponent,
    MenuItemCardComponent,
    UserRegistrationComponent,
    ExaminerDashboardComponent,
    DwTaskContainerComponent,
    ExaminerFinancialSnapshotComponent,
    DwMiniTileComponent,
    DwChartTileComponent,
    ApplicantDashboardComponent,
    ExaminerLocationUtilComponent,
    ExaminerPublicProfileComponent,
    UsreAvatarComponent,
    PaymentInfoComponent,
    PaymentModalComponent,
    CheckridePaymentUtilComponent,
    ExaminerBookingAssignedComponent,
    DateTimeDisplayControlComponent,
    DurationDisplayControlComponent,
    EmailDispalyComponent,
    DurationControlComponent,
    FinancialDashboardComponent,
    AdminFinancialsComponent,
    FinancialDashboardFiltersComponent,
    ExaminerFinancialsComponent,
    ExaminerCalendarCheckComponent,
    CheckridePaymentUtilExaminerComponent,
    ApplicantCheckRideUtilComponent,
    QuestionEditControlComponent,
    GenerateSectionsModalComponent,
    PlanOfActionSectionsComponent,
    SelectSectionsComponent,
    SelectAcsCodesComponent,
    GenerateQuestionsModalComponent,
    RequiredAcsCodesGridComponent,
    PlanOfActionSectionComponent,
    PlanOfActionQuestionComponent,
    PlanOfActionExecuteComponent,
    PlanOfActionExecuteSectionComponent,
    PlanOfActionExecuteQuestionComponent,
    PlanOfActionSectionEditComponent,
    SelectAcsCodesModalComponent,
    CheckridePlanOfActionUtilComponent,
    PoaPrintOptionsModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrderListModule, 
    InputSwitchModule,
    TabViewModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    
    HttpClientModule,
    AgGridModule.forRoot(),
    BsDropdownModule.forRoot(),
    ChartModule,
    
    DevwareCoreModule, 
    DevwareOrmModule,
    DevwareUIModule, 
    DevwareAGGridModule,
    DevwareUILayoutModule,
    DevwareUIMetaDataModule,
    DevwareUiWizardModule,
    DevwareAdminModule,
    DevwareMediaModule,
    FullCalendarModule,
    DayPilotModule,NgbDatepickerModule, NgbTimepickerModule, NgbPopoverModule,
    AppRoutingModule,
  ],
  providers: [
    AppDevwareConfigProviderService,
    CurrentStudentContextService,
    CurrentPilotExaminerContextService,
    CheckridePaymentRefundActionService,
    GridReturnToFinancialsActionService,
    RebookApplicantCheckrideActionService,
    RebookExaminerCheckrideActionService,
    AcceptProposedDateActionService,
    GenerateSectionsActionService,
    GenerateQuestionsActionService,
    AddRequiredAcsCodesActionService,
    PlanOfActionDataSourceService,
    CheckrideFormDataSourceService,
    CheckrideCreatePOAActionService,
    //ExecutePOACompleteActionService,
    ACSWrittenDataContextService,
    POASaveAndExecuteActionService,
    CheckrideSaveAndGotoPOAActionService,
    POAStartCompleteCheckrideActionService,
    POACompleteCheckrideActionService,
    AddMissedAcsCodesActionService,
    POAPrintActionService,
    AcsCodeLookupDataSourceService
  ],
  bootstrap: [AppComponent],
  exports: [
    UserRegistrationComponent
  ]
})
export class AppModule { }
