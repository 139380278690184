import { Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwContextDefinition, DwCustomContextBase, DwCustomContextDefinition, DwMdFormContextInfo, DwOrmDataService, DwQuery, DwQueryHelper, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CheckRideRequestEntity } from '../../../meta-data/app-meta-data.service';



export const ACSWrittenDataContext= 'ACSWrittenDataContext';
@DwCustomContextDefinition({
    keyName: ACSWrittenDataContext,
    display: 'ACS Written Data Context',
    isGlobal: false,
    parentItem: AppMetaDataItemNames.CheckRideRequest
})
@Injectable()
export class ACSWrittenDataContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        
        ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.getCustomContextFunction = (context) => this.getCustomContext(context);

        contextDef.item = AppMetaDataItemNames.ApplicantAcsWrittenResult;
        contextDef.data = null;
        //contextDef.metaDataQueryId = 1019;
    }

    getCustomContext(context: any): any {
        const checkRideRequest: CheckRideRequestEntity = context[DwMdFormContextInfo.DATA_CONTEXT_NAME];

        return checkRideRequest?.ApplicantAcsWrittenResult;
    }

    // buildQuery(resolvedContext: any): DwQuery {
    //     const query = AppMetaData.PilotExaminer.CreateQueryBuilder();

    //     if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
    //         return null;
    //     }
   
    //     query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
    //     const user = this.dwSecurityUserService.securityContext.ApplicationUser;

    //     query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

    //     return query.query;

    // }


}
