import { Injectable } from "@angular/core";

import * as luxon from 'luxon';

const DateTime = luxon.DateTime;

@Injectable({ providedIn: 'root' })
export class DateTimeUtilService {
    calcDurationMinutes(startUtcDateTime: string, endUtcDateTime: string, interval?: number, minValue?: number, maxValue?: number) {

        const startDateTime = this.parseDateTime(startUtcDateTime);
        const endDateTime = this.parseDateTime(endUtcDateTime);

        const duration = endDateTime.diff(startDateTime, 'minutes');

        if (interval) {
            return duration.minutes - (duration.minutes % interval);
        }

        if (minValue && interval < minValue) {
            return minValue;
        }

        if (maxValue && interval > maxValue) {
            return maxValue;
        }
    }


    DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

    readonly minutesConst = 60;
    readonly hoursConst = 60 * 60;
    readonly daysConst = this.hoursConst * 24;

    private parseDateTime(dateTime:string, options?:luxon.DateTimeOptions) : luxon.DateTime {
        let d = DateTime.fromISO(dateTime, options);

        if (!d.isValid) {
            d = DateTime.fromFormat(dateTime, this.DATE_TIME_FORMAT, options);
        }

        return d;
    }

    formatDateTimeToTimezone(dateTime: string, timezone: string, format?: string): string {
        format = format || this.DATE_TIME_FORMAT;

        const opt: luxon.DateTimeOptions = {};

        opt.setZone = true;
        opt.zone = 'UTC';

        const luxonDateTime = this.parseDateTime(dateTime, opt);

        return luxonDateTime.setZone(timezone).toFormat(format);
    }

    isToday(lastCalendarErrorDateTime: string): boolean {
        return this.parseDateTime(lastCalendarErrorDateTime).hasSame(DateTime.local(), 'day');
    }

    getCurrentMonth() {
        return luxon.DateTime.local().month;
    }

    getCurrentYear() {
        return luxon.DateTime.local().year;
    }

    getCurrentDateTime(): string {
        return luxon.DateTime.utc().toISO();
    }
    getCurrentDateTimeLocal(): string {
        return luxon.DateTime.local().toISO();
    }

    convertDateTimeToJson(dateTime: string): string {
        // If already in ISO format, return
        if (dateTime?.indexOf('T') > -1) {
            return dateTime;
        }
        
        const opt: luxon.DateTimeOptions = {};

        opt.setZone = true;
        opt.zone = 'GMT';

        const returnDateTime =  DateTime.fromFormat(dateTime, this.DATE_TIME_FORMAT, opt).toJSON();

        return returnDateTime;
    }
    

    formatDateTimeToLocal(dateTime: string, format?: string): string {

        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return this.formatDateTimeToTimezone(dateTime, browserTimeZone, format);
    }

    convertDateTimeToUtc(dateTime: string): string {
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const opt: luxon.DateTimeOptions = {};

        opt.setZone = true;
        opt.zone = browserTimeZone;

        return DateTime.fromISO(dateTime, opt).setZone('UTC').toISO();
    }

    formatDuration(timeInSeconds: number) {
        const isNegative = (timeInSeconds < 0);
        if (isNegative) {
            timeInSeconds = -timeInSeconds;
        }

        let totalSeconds = timeInSeconds;


        const seconds = (totalSeconds % 60);

        let totalMinutes = (timeInSeconds - seconds) / 60;

        const minutes = totalMinutes % this.minutesConst;

        let totalHours = (totalMinutes - minutes) / 60;

        const hours = totalHours % 24;

        let days = (totalHours - hours) / 24;

        let prefix = '';

        if (days == 1) {
            prefix = '1 day ';
        }
        if (days > 1) {
            prefix = `${days.toFixed(0)} days `
        }

        if (isNegative) {
            prefix = '-' + prefix;
        }

        if (hours > 0) {
            return `${prefix}${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
        }

        return `${prefix}${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
    }



    private pad(num: number, size = 0, decimals = 0) {
        var numString = num.toFixed(0).toString();
        while (numString.length < size) numString = "0" + numString;
        return numString;
    }


    formatDuration2(timeInSeconds: number) {
        const isNegative = (timeInSeconds < 0);
        if (isNegative) {
            timeInSeconds = -timeInSeconds;
        }

        let totalSeconds = timeInSeconds;


        const seconds = (totalSeconds % 60);

        let totalMinutes = (timeInSeconds - seconds) / 60;

        const minutes = totalMinutes % this.minutesConst;

        let totalHours = (totalMinutes - minutes) / 60;

        const hours = totalHours % 24;

        let days = (totalHours - hours) / 24;

        let prefix = '';

        if (days == 1) {
            prefix = '1 day ';
        }
        if (days > 1) {
            prefix = `${days.toFixed(0)} days `
        }

        if (isNegative) {
            prefix = '-' + prefix;
        }

        let result = prefix;

        if (hours == 1) {
            result += `${this.pad(hours, 1)} hour `;
        }
        if (hours > 1) {
            result += `${this.pad(hours, 1)} hours `;
        }

        if (minutes > 0) {
            result += `${this.pad(minutes, 2)} minutes`;
        }

        return result;

        // return `${prefix}${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
    }




}