import { Inject, Injectable } from "@angular/core";
import { DwDataItemKeyValues, DwDataRequest, DwDatasource, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { Observable } from "rxjs";

@DwDatasource({
    keyName: 'CheckRideFormDataSource',
    display: 'Checkride Form data Source',
    itemName: AppMetaDataItemNames.CheckRideRequest,
})
@Injectable()
export class CheckrideFormDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.PlanOfAction);
    }

    initDataSource() {
        
    }
    
    loadItemFromQuery(query: DwQuery, keyValues: DwDataItemKeyValues | number): Observable<any> {

        const checkrideQuery = AppMetaData.CheckRideRequest.CreateQueryBuilder(query);

        const acsWrittenResultQuery = AppMetaData.ApplicantAcsWrittenResult.CreateQueryBuilder();

        acsWrittenResultQuery.addPrefetch(r => r.ApplicantAcsWrittenResultMissedCode);

        let prefetchWrittenResult = query.Prefetch.find(p => p.RelationName === AppMetaData.CheckRideRequest.Relations.ApplicantAcsWrittenResult.relationName);

        if (!prefetchWrittenResult) {
            checkrideQuery.addPrefetch(r => r.ApplicantAcsWrittenResult, acsWrittenResultQuery.query);

            prefetchWrittenResult = query.Prefetch.find(p => p.RelationName === AppMetaData.CheckRideRequest.Relations.ApplicantAcsWrittenResult.relationName);
        }

        prefetchWrittenResult.Query = acsWrittenResultQuery.query;


        return super.loadItemFromQuery(checkrideQuery.query, keyValues);
    }

   
    // prepareRequestQuery(request: DwDataRequest): DwQuery {
    //     const baseQuery = super.prepareRequestQuery(request);

    // }

}