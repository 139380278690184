import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, PlanOfActionEntity, PlanOfActionSectionEntity } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../../plan-of-action/components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../../plan-of-action/components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";

@DwAction({
    keyName: 'checkride-create-poa-action',
    display: 'Generate Plan Of Action',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.CheckRideRequest, AppMetaDataItemNames.ApplicantAcsWrittenResult, AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        useParentDisplay: true,
        buttonClass: 'btn btn-warning mr-1 mt-1',
        iconClass: 'fa fa-map'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: '',
    }
})
@Injectable()
export class CheckrideCreatePOAActionService implements DwActionHandler {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private planofActionGeneratorService: PlanOfActionGeneratorService,
        private dwModalService: DwModalService,
        private dwRoutingService: DwRoutingService

    ) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        const checkride = actionState.formApi.getFormData<CheckRideRequestEntity>();

        return actionState.formApi.saveData(true)
            .pipe(map((result) => {

                if (result.cancelled || !result.success) {
                    actionState.formApi.showAbortMessage(result.message);
                    return result;
                }

                const url = `/plan-of-action/plans/add?CheckRideRequestId=${checkride.CheckRideRequestId}&AcsTestId=${checkride.ApplicantAcsWrittenResult.AcsTestId}`;
        
                this.dwRoutingService.navigateToUrl(url, null);
                
                return { success: true };
            }));
    }

}