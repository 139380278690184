export const environment = {
    production: true,
    baseTitle: 'Pilexos',
    environmentMessage: '',
    //apiRoot: "https://localhost:44368/api/v1",
    //apiRoot: "https://localhost:44370/api/v1",
    
    apiRoot: "https://pilexos-prod-api2.azurewebsites.net/api/v1",
    alternateApiRoots : {
      'reporting': 'https://shared-api.devwareapps.com/api/v1'
  },
    
    tenantKey: '16970b66-9788-4e12-aeb3-36bda56bd32f',
  
    allowConfiguration: true,
    enabledConsoleLogging: false,
    
    googleAnalyticsTrackingToken: null,
    showContactUs: true,
    contactUsEmail: 'support@pilexos.com',
    disabledUserInactvityTimeout: true
  };
  