
<div dwRow>
    <div dwCol="6">
        <ng-container *ngIf="showEdit">
            <label class="label">Section Name</label>
            <input type="text" [(ngModel)]="planOfActionSection.SectionName" (ngModelChange)="sectionTitleChange()" class="form-control" />
        </ng-container>
        <ng-container *ngIf="!showEdit">
            <label class="label">{{sectionTitle}}</label>
        </ng-container>
    </div>
</div>