<div class="pull-right" style="width:400px;">
    <div class="btn-group btn-group-toggle mb-2 mt-1 pull-right" data-toggle="buttons">
        <label class="btn btn-primary" [ngClass]="{active : manageSections}">
            <i class="fa fa-tasks-alt"></i> <input type="radio" name="manageSections2" [(ngModel)]="manageSections"
                (ngModelChange)="manageSectionsChange()" [value]="true" id="option1" autocomplete="off" checked> Manage
            Sections
        </label>
        <label class="btn btn-primary" [ngClass]="{active : !manageSections}">
            <i class="fa fa-question-circle"></i> <input type="radio" name="manageSections2"
                [(ngModel)]="manageSections" (ngModelChange)="manageSectionsChange()" [value]="false" id="option2"
                autocomplete="off"> Setup Questions
        </label>
    </div>
</div>
<p-tabView class="p-tabview-panels-hidden" [(activeIndex)]="activeTabIndex"
    (onChange)="handleTabChange(activeTabIndex)">
    <ng-container *ngFor="let acsTestPortion of acsTestPortions; let i = index">
        <p-tabPanel #tabPanel2 header="{{acsTestPortion.AcsTestPortion}}" [selected]="i==0">

        </p-tabPanel>
    </ng-container>
</p-tabView>

<div class="p-tabview">
    <div class="p-tabview-panels">
        <ng-container *ngIf="manageSections">
            <dw-button-bar>
                <button class="btn btn-warning" (click)="addManualSection()"><i class="fa fa-tasks-alt"></i> Add Manual
                    Section</button>
                <button class="btn btn-warning" (click)="addSections()"
                    *ngIf="currentAcsTestPortion.AllowAcsSections"><i class="fa fa-tasks-alt"></i> Add ACS
                    Sections</button>


            </dw-button-bar>
            <dw-ordered-list [(items)]="currentSections" (itemsChange)="onSectionsReorder($event)"
                (itemRemoved)="onSectionRemoved($event)" *ngIf="currentSections?.length > 0">
                <ng-container *dwListItem="let section">
                    <app-plan-of-action-section-edit [planOfAction]="planOfAction" [planOfActionSection]="section"
                        [acsCodeStatuses]="acsCodeStatuses" [acsTestPortion]="currentAcsTestPortion"
                        [acsReferenceInfo]="acsReferenceInfo"
                        (planOfActionSectionChange)="planOfActionSectionChange($event)"></app-plan-of-action-section-edit>
                </ng-container>
            </dw-ordered-list>

            <ng-container *ngIf="currentSections?.length == 0">
                No sections have been added.
            </ng-container>

        </ng-container>
        <ng-container *ngIf="!manageSections">
            <ng-container *ngFor="let currentSection of currentSections; let i = index">
                <app-plan-of-action-section [planOfAction]="planOfAction" [planOfActionSection]="currentSection"
                    [acsCodeStatuses]="acsCodeStatuses" [acsTestPortion]="currentAcsTestPortion"
                    [optionsShowQuestionForAcsCode]="planOfAction?.OptionsShowQuestionForAcsCode"
                    [collapsed]="selectedSectionIndex != i" (collapsedChange)="onSectionCollapsedChange($event, i)"
                    [acsReferenceInfo]="acsReferenceInfo"
                    (planOfActionSectionChange)="planOfActionSectionChange($event)"
                    [directMode]="true"></app-plan-of-action-section>
            </ng-container>
            <ng-container *ngIf="currentSections?.length == 0">
                No sections have been added. Switch to Manage sections to add some
            </ng-container>
        </ng-container>
    </div>
</div>