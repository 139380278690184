import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwFormPrintConfig, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestStatusAllItems, CheckRideRequestSubStatusAllItems, PlanOfActionDetailsQueryEntity, PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";
import { PoaPrintOptionsModalComponent } from "../components/poa-print-options-modal/poa-print-options-modal.component";
import { PlanOfActionExecuteComponent } from "../components/plan-of-action-execute/plan-of-action-execute.component";

@DwAction({
    keyName: 'poa-print-action',
    display: 'Print Plan Of Action',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        useParentDisplay: false,
        buttonText: 'Print',
        buttonClass: 'btn btn-secondary mr-1 mt-1',
        iconClass: 'fa fa-print'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: 'Exporting...',
    }
})
@Injectable()
export class POAPrintActionService implements DwActionHandler {

    constructor(private dwRoutingService: DwRoutingService, private dwModalService: DwModalService) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        PlanOfActionExecuteComponent.printCurrentAcsTestPortions = null;

        const formPrintConfigOptions: DwFormPrintConfig = 
        {
            printConfigKeyOverride: 'dep-admin-execute-print',
            exportPDFFileName: 'PlanOfAction.pdf',
            renderMultiplePage: true 
        };

        return actionState.formApi.print(formPrintConfigOptions);

        // const ob : Observable<any> = actionState.formApi.print(formPrintConfigOptions);

        // return ob.pipe(map((result) => {
        //     return { success: true };
        // }));

        // return of({ success: true });
    }
    
    showPrintOptions(actionState: DwMdFormActionState<any>): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig<AcsCodeSelectionModalOptions>();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.large;

        modalConfig.component = PoaPrintOptionsModalComponent;

        modalConfig.title = 'Create PDF';
        // modalConfig.data = {
        //     allowQuestionSelect: false,
        //     acsTestId: applicantWritten.AcsTestId,
        //     acsCodeStatuses: acsCodeStatuses
        //     //planOfAction: planOfAction,
        // };

        return this.dwModalService.showModal<any, AcsCodeSelectionModalResult>(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwActionResult();

                if (result.cancelled) {
                    result.cancelled = true;

                    return result;
                }

                if (modalResult.data) {
                    
                }

                return result;
            }));
    }

}