<dw-container [options]="containerOptions" [(collapsed)]="collapsed" (collapsedChange)="collapsed=$event"
    *ngIf="!inPrintMode && containerOptions">
    <span header>
        {{sectionTitle}}

        <div class="pull-right legend" *ngIf="showAcsCode">
            <img [src]="missingAcsCodeImageUrl" class="missed-acs-code" title="Indicated missed ACS Codes" /> Missed ACS
            Codes
        </div>
    </span>
    <div body>
        <div *ngIf="planOfActionSection.PlanOfActionSectionQuestion.length === 0">
            <div class="alert alert-info">No questions found for this section</div>
        </div>
        <ng-container *ngFor="let question of planOfActionSection.PlanOfActionSectionQuestion">
            <app-plan-of-action-execute-question [planOfAction]="planOfAction" [question]="question"
                [acsCodeStatuses]="acsCodeStatuses" [acsCodes]="acsCodes" [showAcsCode]="showAcsCode"
                [result]="resultMapping[question.PlanOfActionSectionQuestionId]"
                (resultChange)="updateResult($event)"></app-plan-of-action-execute-question>
        </ng-container>
    </div>
</dw-container>

<ng-container *ngIf="inPrintMode && planOfActionSection.PlanOfActionSectionQuestion.length > 0 && containerOptions">
    <!-- <dw-container [options]="containerOptions" [(collapsed)]="collapsed" (collapsedChange)="collapsed=$event">
        <span header>
            {{sectionTitle}}

            <div class="pull-right legend" *ngIf="showAcsCode">
                <img [src]="missingAcsCodeImageUrl" class="missed-acs-code" title="Indicated missed ACS Codes" /> Missed
                ACS Codes
            </div>
        </span>
    </dw-container> -->
    <div class="print-section-header">
        <h2>{{sectionTitle}}</h2>

        <ng-container *ngFor="let question of planOfActionSection.PlanOfActionSectionQuestion">
            <app-plan-of-action-execute-question [planOfAction]="planOfAction" [question]="question"
                [acsCodeStatuses]="acsCodeStatuses" [acsCodes]="acsCodes" [showAcsCode]="showAcsCode"
                [result]="resultMapping[question.PlanOfActionSectionQuestionId]"
                (resultChange)="updateResult($event)"></app-plan-of-action-execute-question>
        </ng-container>
    </div>
</ng-container>