import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AcsCodesLookupQueryEntity, PlanOfActionSectionQuestionEntity } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem, AcsCodeStatuses } from '../../../plan-of-action/models/acs-code-items.model';

@Component({
  selector: 'app-plan-of-action-question',
  templateUrl: './plan-of-action-question.component.html',
  styleUrls: ['./plan-of-action-question.component.scss']
})
export class PlanOfActionQuestionComponent implements OnInit, OnChanges {

  @Input() question: PlanOfActionSectionQuestionEntity;
  @Input() questionChange = new EventEmitter<PlanOfActionSectionQuestionEntity>();

  @Input() acsCodes: AcsCodesStorageItem;
   
  @Input() currentAcsCodes: AcsCodesLookupQueryEntity[];

  @Input() showAcsCode: boolean = true;
  @Input() showQuestion: boolean = true;
  @Input() acsCodeStatuses: AcsCodeStatuses;

  currentAcsCode: AcsCodesLookupQueryEntity;
  currentAscCodeStatus: AcsCodeStatuses;

  inEditMode: boolean = false;
  isMissedCode: boolean;

  constructor() { }
 
  ngOnInit(): void {
    this.setupQuestion();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupQuestion();
  }

  private setupQuestion() {
    if (!this.question || !this.acsCodes) {
      return;
    }

    if (this.showAcsCode) {
      if (this.question.AcsCodeId) {
        this.currentAcsCode = this.acsCodes.codesById[this.question.AcsCodeId];

        this.isMissedCode = !!this.acsCodeStatuses.codesById[this.question.AcsCodeId];

      } else {
        this.inEditMode = true;
      }
    } else {
      if (!this.question?.QuestionHtml) {
        this.inEditMode = true;
      }
    }

  }

  onQuestionChange() {
    this.questionChange.emit(this.question);
  }

  onAcsCodeChanged() {
    if (this.question.AcsCodeId) {
      this.currentAcsCode = this.acsCodes.codesById[this.question.AcsCodeId];
    }

    this.onQuestionChange();
  }

}
