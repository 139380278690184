<ng-container *ngIf="question">
    <div dwRow>
        <div dwCol="2" *ngIf="showAcsCode">
            <label class="label" [hidden]="hideLabels">ASC Code  <i class="fa fa-times dw-red" title="Missed ACS Code" [hidden]="!isMissedCode"></i></label>

            <ng-container *ngIf="!inEditMode">
                <div  class="form-control" (click)="inEditMode = true">{{currentAcsCode?.AcsCode}}</div>
                
<!-- 
                <a href="javascript:void(0)" (click)="inEditMode = true">Change</a> -->
            </ng-container>
            <ng-container *ngIf="inEditMode">
                <p-dropdown [options]="currentAcsCodes" optionLabel="AcsCode" display="chip" optionValue="AcsCodeId"
                    [(ngModel)]="question.AcsCodeId" (ngModelChange)="onAcsCodeChanged($event)" [style]="{'width':'100%'}"
                    [filter]="true" appendTo="body"></p-dropdown>

                
            </ng-container>
        </div>
        <div [dwCol]="showAcsCode ? 10 : 12" *ngIf="showQuestion">
            <label class="label" [hidden]="hideLabels">Question</label>

            <div *ngIf="!inEditMode"  class="form-control form-control-expandable" (click)="inEditMode = true">
                <dw-html-view [ngModel]="question.QuestionHtml"></dw-html-view>
            </div>
            <ng-container *ngIf="inEditMode">
                <dw-html-edit [(ngModel)]="question.QuestionHtml" (ngModelChange)="onQuestionChange($event)" ></dw-html-edit>
            </ng-container>

            <span *ngIf="showAcsCode">{{currentAcsCode?.AscCodeDescription}}</span>
        </div>
        <div dwCol="10" *ngIf="!showQuestion">
            <label class="label" [hidden]="hideLabels">ACS Code Description</label>
            <div class="form-control form-control-expandable">
                <span *ngIf="showAcsCode">{{currentAcsCode?.AscCodeDescription}}</span>
            </div>
        </div>
    </div>
</ng-container>