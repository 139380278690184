<div class="plan-of-action-execute">
    <dw-container [options]="acsTestSelectionContainer"
        *ngIf="!currentAcsTestPortion && !inPrintMode && acsTestDetails?.length > 0">
        <div body>
            <div dwRow>
                <div dwCol="3">

                </div>
                <div dwCol="2 label">
                    % Complete
                </div>
                <div dwCol="2  label">
                    Sections
                </div>
                <div dwCol="2  label">
                    Questions
                </div>
                <div dwCol="3  label">

                </div>
            </div>
            <div dwRow *ngFor="let acsTestDetail of acsTestDetails; let i = index">
                <div dwCol="3">
                    <button class="btn btn-secondary ml-1" (click)="print(acsTestDetail)" title="Export to PDF">
                        <i class="fa fa-file-pdf"></i>

                        <i class="fa fa-spinner fa-spin ml-1 dw-orange" title="Exporting..."
                            *ngIf="exporting && exportingTestPortionId == acsTestDetail.acsTestPortion.AcsTestPortionId"></i>
                    </button>

                    {{acsTestDetail.acsTestPortion.AcsTestPortion}}
                </div>
                <div dwCol="2">
                    {{acsTestDetail.percentComplete}}%
                </div>
                <div dwCol="2">
                    {{acsTestDetail.sectionCount}}
                </div>
                <div dwCol="2">
                    {{acsTestDetail.questionsCompleted}} / {{acsTestDetail.questionCount}}
                </div>
                <div dwCol="3">
                    <button class="btn btn-success" (click)="goToAcsTestPortion(acsTestDetail)"
                        [hidden]="acsTestDetail.percentComplete!=0">Start</button>
                    <button class="btn btn-success" (click)="goToAcsTestPortion(acsTestDetail)"
                        [hidden]="acsTestDetail.percentComplete==0 || acsTestDetail.percentComplete == 100 ">Continue</button>
                    <button class="btn btn-success" (click)="goToAcsTestPortion(acsTestDetail)"
                        [hidden]="acsTestDetail.percentComplete!=100">Review</button>
                </div>
            </div>
        </div>

        <dw-loading [show]="exporting" text="Printing"></dw-loading>
    </dw-container>

    <ng-container *ngIf="currentAcsTestPortion && !inPrintMode">
        <dw-container [options]="acsTestPortionContainer">
            <span header>{{currentAcsTestPortion.acsTestPortion.AcsTestPortion}}</span>
            <div body>

                <ng-container *ngIf="showAllSections">
                    <div class="button-bar-simple hide-print" style="overflow:hidden">
                        <!-- <div class="pull-left" *ngIf="showAllSectionsToggle">
                        Show All Sections <dw-yesno [(ngModel)]="showAllSections"></dw-yesno><br>
                    </div> -->
                        <div class="pull-right ml-1" style="margin-top:-5px" *ngIf="showAllSectionsToggle">
                            <ng-container *ngTemplateOutlet="sectionToggle"></ng-container>
                        </div>
                        <div class="pull-right">
                            <button class="btn btn-primary" (click)="complete()">Save and Return</button>
                        </div>


                    </div>
                    <ng-container *ngFor="let currentSection2 of currentTestSections; let i = index">

                        <app-plan-of-action-execute-section [planOfAction]="planOfAction"
                            [planOfActionSection]="currentSection2" [sectionTypes]="sectionTypes"
                            [acsCodeStatuses]="acsCodeStatuses"
                            [planOfActionQuestionResults]="planOfActionQuestionResults"
                            (resultChange)="resultChange($event)"
                            [useTitleOnlyContainer]="true"></app-plan-of-action-execute-section>

                    </ng-container>
                    <div class="button-bar-simple  hide-print">
                        <div class="pull-right">
                            <button class="btn btn-primary  pull-right" (click)="complete()">Save and Return</button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!showAllSections">
                    <div class="button-bar-simple  hide-print" style="overflow:hidden">
                        <div class="pull-left" *ngIf="!showAllSections">
                            <button class="btn btn-primary" (click)="previousSection()" *ngIf="hasPrevious"
                                title="Previous and Save">Previous</button>
                        </div>
                        <div class="pull-left">
                            <dw-control-wrapper-with-icons [showSuffix]="true" wrapControl="false">
                                <p-dropdown control [options]="sectionList" optionLabel="Display" display="chip"
                                    optionValue="Id" [(ngModel)]="selectedSectionId" [style]="{'min-width':'300px'}"
                                    [filter]="true" appendTo="body"></p-dropdown>

                                <span suffix class="input-group-text" class="btn btn-primary" (click)="goToSection()">
                                    Go to
                                    Section</span>
                            </dw-control-wrapper-with-icons>
                        </div>
                        <div class="pull-right">
                            <div class="pull-right ml-1" style="margin-top:-5px" *ngIf="showAllSectionsToggle">
                                <ng-container *ngTemplateOutlet="sectionToggle"></ng-container>
                            </div>
                            <div class="pull-right">
                                <button class="btn btn-primary" (click)="stop()" *ngIf="hasNext">Save and
                                    Return</button>
                                <button class="btn btn-primary" (click)="complete()" *ngIf="!hasNext">Save and
                                    Return</button>
                                <button class="btn btn-success" (click)="nextSection()" *ngIf="hasNext"
                                    title="Next and Save">Next</button>
                            </div>
                        </div>
                    </div>
                    <app-plan-of-action-execute-section [planOfAction]="planOfAction"
                        [planOfActionSection]="currentSection" [sectionTypes]="sectionTypes"
                        [acsCodeStatuses]="acsCodeStatuses" [planOfActionQuestionResults]="planOfActionQuestionResults"
                        (resultChange)="resultChange($event)"
                        [useTitleOnlyContainer]="true"></app-plan-of-action-execute-section>

                    <div class="button-bar-simple  hide-print">
                        <div class="pull-left">
                            <button class="btn btn-primary" (click)="previousSection()" *ngIf="hasPrevious"
                                title="Previous and Save">Previous</button>
                        </div>
                        <div class="pull-right">
                            <button class="btn btn-primary  pull-right" (click)="stop()" *ngIf="hasNext">Save and
                                Return</button>
                            <button class="btn btn-primary  pull-right" (click)="complete()" *ngIf="!hasNext">Save and
                                Return</button>
                            <button class="btn btn-success pull-right" (click)="nextSection()" *ngIf="hasNext"
                                title="Next and Save">Next</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </dw-container>
    </ng-container>
</div>

<ng-container *ngIf="inPrintMode">
    <ng-container *ngFor="let printTestDetail of printTestDetails">
        <!-- <dw-container [options]="printTestDetail.containerOptions">
            <span header>{{printTestDetail.acsTestPortion.AcsTestPortion}}</span>
            <div body>
               
            </div>
        </dw-container> -->

        <div class="print-page-header">
            <h1>{{printTestDetail.acsTestPortion.AcsTestPortion}}</h1>

            <ng-container *ngFor="let currentSection2 of printTestDetail.sections; let i = index">
                <div dwRow>
                    <div dwCol="12">
                        <app-plan-of-action-execute-section [planOfAction]="planOfAction"
                            [planOfActionSection]="currentSection2" [sectionTypes]="sectionTypes"
                            [acsCodeStatuses]="acsCodeStatuses" [inPrintMode]="inPrintMode"
                            [planOfActionQuestionResults]="planOfActionQuestionResults"
                            (resultChange)="resultChange($event)"
                            [useTitleOnlyContainer]="true"></app-plan-of-action-execute-section>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #sectionToggle>
    <div class="btn-group btn-group-toggle mb-2 mt-1 pull-right" data-toggle="buttons">
        <label class="btn btn-primary" [ngClass]="{active : showAllSections}" title="Show All Sections">
            <i class="fa fa-boxes"></i> <input type="radio" name="manageSections2" [(ngModel)]="showAllSections"
                [value]="true" id="option1" autocomplete="off" checked>
        </label>
        <label class="btn btn-primary" [ngClass]="{active : !showAllSections}" title="Show Single Section">
            <i class="fa fa-box"></i> <input type="radio" name="manageSections2" [(ngModel)]="showAllSections"
                [value]="false" id="option2" autocomplete="off">
        </label>
    </div>
</ng-template>