import { Inject, Injectable } from "@angular/core";
import { DwDataItemKeyValues, DwDataRequest, DwDatasource, DwItemType, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataHierarchy, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwQueryPrefetchPath, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { Observable } from "rxjs";

@DwDatasource({
    keyName: 'AcsCodeLookupDataSource',
    display: 'Acs Code Lookup Data Source',
    itemName: AppMetaDataItemNames.AcsCodeLookupRequest,
})
@Injectable()
export class AcsCodeLookupDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.PlanOfAction);
    }

    initDataSource() {
        this.setupOrmDataHierarchyFromQuery(this.item, this.ormDataHierarchy);
    }

    private setupOrmDataHierarchyFromQuery(item: DwItemType | DwQuery, ormDataHierarchy?: DwOrmDataHierarchy[]) {
        const acsWrittenHierarchy: DwOrmDataHierarchy = {
            itemName: AppMetaData.ApplicantAcsWrittenResult.ItemDetail.itemName,
            propertyName: AppMetaData.AcsCodeLookupRequest.Relations.ApplicantAcsWrittenResult.propertyName,
            children: [{
                itemName: AppMetaData.ApplicantAcsWrittenResultMissedCode.ItemDetail.itemName,
                propertyName: AppMetaData.ApplicantAcsWrittenResult.Relations.ApplicantAcsWrittenResultMissedCode.propertyName,
            }]
        };

        ormDataHierarchy.push(acsWrittenHierarchy);
    }

    loadItemFromQuery(query: DwQuery, keyValues: DwDataItemKeyValues | number): Observable<any> {
        const updatedQuery = this.buildFinalQuery(query);

        return super.loadItemFromQuery(updatedQuery, keyValues);
    }

    private buildFinalQuery(query: DwQuery): DwQuery {
        const acsCodeLookupQuery = AppMetaData.AcsCodeLookupRequest.CreateQueryBuilder(query);

        let acsWrittenResultQuery = AppMetaData.ApplicantAcsWrittenResult.CreateQueryBuilder();


        acsWrittenResultQuery.addPrefetch(r => r.ApplicantAcsWrittenResultMissedCode);

        let acsWrittePrefetch = acsCodeLookupQuery.query.Prefetch.find(p => p.RelationName == AppMetaData.AcsCodeLookupRequest.Relations.ApplicantAcsWrittenResult.relationName);

        if (acsWrittePrefetch) {
            acsWrittePrefetch.Query = acsWrittenResultQuery.query;
        } else {
            acsCodeLookupQuery.addPrefetch(r => r.ApplicantAcsWrittenResult, acsWrittenResultQuery.query);
        }

        return acsCodeLookupQuery.query;
    }



}