import { Component, EventEmitter, Inject, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContainerOptions, DwContext, DwExpressionService, DwFormActionInterceptResult, DwFormActionInterceptor, DwFormActionKeys, DwFormContextMode, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormConfig, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwSectionBasePreference, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, deepCopy, formatDateTimeFromLocal } from '@devwareapps/devware-cap';
import { Observable, Subscription, of } from 'rxjs';

import { AppMetaData, AppMetaDataItemNames, ApplicantPlaceholderEntity, CheckRideOutcomeAllItems, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, CheckRideResultEntity, CheckRideScenarioEntity, CheckrideGroupACSTestQueryEntity, PilotExaminerLocationEntity, PilotExaminerSearchQueryEntity, StudentApplicantEntity } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { CheckCalendarAvailabilityRequestDTO } from '../../../examiner/models/check-calendar-availability-request.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { CurrentStudentContext } from 'src/app/features/shared/contexts/current-student-context/current-student-context.service';
import { CheckCalendarAvailabilityResultDTO } from '../../../examiner/models/check-calendar-availability-result.model';
import { map } from 'rxjs/operators';

@DwComponent({
  key: 'checkride-plan-of-action-util',
  name: ' Checkride - Plan of Action Util',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.CheckRideRequest,
  isGlobal: false
})
@Component({
  selector: 'app-checkride-plan-of-action-util',
  template: ``
})
export class CheckridePlanOfActionUtilComponent extends DwSectionBaseComponent {
  checkRideRequest: CheckRideRequestEntity;

  originalCheckRideRequest: CheckRideRequestEntity;

  formApi: DwMetaDataFormApi;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupDataWatch();

  }


  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }

    this.context['Checkride-Group_ACS_Test']?.subscribe((groupAcsTest) => {
      this.updateAcsTest(groupAcsTest)
    });
  }

  private checkForDataChange() {

    const checkRideRequest = this.formApi.getFormData<CheckRideRequestEntity>();

    this.checkRideRequest = checkRideRequest;

    if (!this.originalCheckRideRequest) {
      this.originalCheckRideRequest = deepCopy(checkRideRequest);
    }

    // if (this.checkRideRequest.CheckRideGroupId != this.originalCheckRideRequest?.CheckRideGroupId) {
    //   this.updateAcsTest();
    // }

  }

  private updateAcsTest(groupAcsTest: CheckrideGroupACSTestQueryEntity) {
    if (!groupAcsTest) {
      return;
    }

    if (this.checkRideRequest.ApplicantAcsWrittenResult.AcsTestId != groupAcsTest.AcsTestAcsTestId) {
      
      const applicantAcsWrittenFG = this.formGroup.get('ApplicantAcsWrittenResult') as FormGroup;

      const acsTestIdFC = applicantAcsWrittenFG.get('AcsTestId') as FormControl;

      acsTestIdFC.setValue(groupAcsTest.AcsTestAcsTestId);

      // this.checkRideRequest.ApplicantAcsWrittenResult.AcsTestId = groupAcsTest.AcsTestAcsTestId;
  
      // this.formApi.formGroup.patchValue(this.checkRideRequest);
    }
  }
}

