import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { DwComponent, DwComponentType, DwContext, DwFormControl, DwFormControlInit, DwFormFieldConfig, DwLookupInfo, DwMetaDataService, DwMetaDataServiceToken, formatDateTimeToLocal, getMomentValue } from '@devwareapps/devware-cap';
import { AirportEntity, AirportWithAddressInfoQueryEntity, AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';
import { ExaminerRepositoryService } from '../../../examiner/services/examiner-repository.service';
import { Observable } from 'rxjs';
import { DateTimeUtilService } from '../../util/date-time-util.service';

@DwComponent({
  key: 'pilexos-date-time-display',
  name: 'Date Time Display',
  componentType: DwComponentType.formControl,
  isGlobal: true
})
@Component({
  selector: 'app-date-time-display-control',
  template: `
    <div class="read-only" [title]="timezone?.TimeZoneDescription || ''">{{ translatedDateTime }} <span [hidden]="!timezone">{{ timezone?.TimezoneShortName}}</span></div>

    <div class="dw-red" *ngIf="translatedDateTime != localDateTime" title="The Checkride is in a different timezone than your current timezone">Different Timezone</div>
  `
})
export class DateTimeDisplayControlComponent implements DwFormControl , DwFormControlInit{
  fieldDef: DwFormFieldConfig;
  formGroup: FormGroup;
  context: DwContext;
  useDisplayField?: boolean;
  
  
  dateTimeValue: any;
  timezoneId: any;
  timezoneLookups: DwLookupInfo[];
  timezone: DwLookupInfo;
  timezoneIANAIdentifier: any;
  translatedDateTime: string;
  airportInfo: AirportWithAddressInfoQueryEntity;
  localDateTime: string;
  localTimezone: DwLookupInfo;

  constructor(@Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private examinerRepositoryService: ExaminerRepositoryService,
    private dateTimeUtilService: DateTimeUtilService
  ) { }


  initControl(): void {
    // More checks needed since the data might not be loaded yet
    this.dateTimeValue = this.control.value;
  }

  ngOnInit() {
    this.updateValue();

    this.control.valueChanges.subscribe(value => {
      this.updateValue();
    });

    this.getAirportInfo()?.subscribe(airportInfo => {

      this.airportInfo = airportInfo;

      this.updateValue();
    })


    this.dwMetaDataService.getLookup(AppMetaDataLookups.TimeZoneAllItems)
        .subscribe(timezoneLookups => {
            this.timezoneLookups = timezoneLookups;

            this.updateValue();
        });

      
  }


  private updateValue() {
    
    if (this.dateTimeValue == this.control?.value && this.timezoneId == this.airportInfo?.AirportAddressTimezoneId || !this.timezoneLookups) {
      return;
    }

    this.dateTimeValue = this.control?.value;
    this.timezoneId = this.airportInfo?.AirportAddressTimezoneId;

    this.timezone = this.timezoneLookups.find(tz => tz.Id == this.timezoneId);

    this.timezoneIANAIdentifier = this.timezone?.IANAIdentifier;


    
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    this.localTimezone = this.timezoneLookups.find(tz => tz.IANAIdentifier == browserTimeZone);
    
    
    this.localDateTime = this.dateTimeUtilService.formatDateTimeToLocal(this.dateTimeValue);
    
    if (this.localDateTime.indexOf('Invalid') > -1) { 
      this.localDateTime = formatDateTimeToLocal(this.dateTimeValue, true, true);
    }
    
    if (this.timezoneIANAIdentifier) {
      this.translatedDateTime = this.dateTimeUtilService.formatDateTimeToTimezone(this.dateTimeValue, this.timezoneIANAIdentifier);
    } else {
      this.translatedDateTime = this.localDateTime;
    }
  
  }

  /*
   * Returns the rating Form Control to get or set it's value
   */
  get control(): AbstractControl {
    return this.formGroup.get(this.fieldDef.attrName);
  }

   /*
   * Returns the rating Form Control to get or set it's value
   */
   getAirportInfo(): Observable<AirportWithAddressInfoQueryEntity> {
    return this.context['Airport-Check_Ride_Request']?.asObservable();
  }

}
