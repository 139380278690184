
<div *ngIf="showGrid">
<div class="pull-right" style="margin-bottom: -40px" *ngIf="showLegend">
    <div style="line-height: 1.5; width:120px;" class="pull-left" title="ACS Code has NOT been added to this Plan of Action">
        <div class="dw-red-bg" style="width:25px;height:25px; margin:5px; float: left">
        </div>

        <div class="mt-1">Not Added</div>
    </div>

    <div style="line-height: 1.5; width:110px;"  class="pull-left" title="ACS Code has been added to this Plan of Action">
        <div class="dw-green-bg" style="width:25px;height:25px; margin:5px; float: left">
        </div>

        <div class="mt-1">Added</div>
    </div>
</div>

<dw-meta-data-grid #dwGrid [item]="aosCodesItem" configKeyPart="select-codes-form" [filters]="filters" allowConfig="true"
    [preferences]="codesGridPreferences" (gridEvent)="onCodeGridEvent($event)"
    [gridCallbacks]="gridCallbacks"></dw-meta-data-grid>


</div>
<!-- 
    Filters: {{filters | json}}

    Asc Code Statuses: {{acsCodeStatuses | json}} -->