import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestSubStatusAllItems, PlanOfActionDetailsQueryEntity, PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../../plan-of-action/components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../../plan-of-action/components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";

@DwAction({
    keyName: 'execute-poa-complete-action',
    display: 'Start Complete Plan Of Action',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.CheckRideRequest, AppMetaDataItemNames.ApplicantAcsWrittenResult, AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        useParentDisplay: false,
        buttonText: 'Complete Checkride',
        buttonClass: 'btn btn-warning mr-1 mt-1',
        iconClass: 'fa fa-th-list'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: '',
    }
})
@Injectable()
export class POAStartCompleteCheckrideActionService implements DwActionHandler {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private planofActionGeneratorService: PlanOfActionGeneratorService,
        private dwModalService: DwModalService,
        private dwRoutingService: DwRoutingService

    ) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {
        const planOfAction = actionState.formApi.getFormData<PlanOfActionEntity>();

        const planOfActionDetails: PlanOfActionDetailsQueryEntity = actionState.context['Plan_Of_Action_Details'];

        planOfAction.PlanOfActionStatusId = PlanOfActionStatusAllItems.Complete;

        planOfAction.PlanOfActionResult.CheckRideStartDateTime = planOfActionDetails.CheckRideFinalDateTime;

        if (planOfAction.CheckRideRequest?.CheckRideRequestId) {
            planOfAction.CheckRideRequest.CheckRideResult.CheckRideDateTime = planOfAction.PlanOfActionResult.CheckRideStartDateTime;
            planOfAction.CheckRideRequest.CheckRideRequestSubStatusId = CheckRideRequestSubStatusAllItems.Completed;
        }

        actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction);
        actionState.formApi.formGroup.patchValue(planOfAction);
        actionState.formApi.setFormData(planOfAction);

        return of({ success: true });
    }

}