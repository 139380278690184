import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaDataItemNames, PlanOfActionEntity, PlanOfActionSectionEntity } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../../plan-of-action/components/generate-sections-modal/generate-sections-modal.component";
import { map } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../../plan-of-action/components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";

@DwAction({
    keyName: 'add-missed-acs-codes-action',
    display: 'Add Missed Codes to Plan',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        buttonClass: 'btn btn-warning mr-1 mt-1',
        buttonText: 'Add Missed Codes to Plan',
        iconClass: 'fa fa-tasks-alt'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: '',
    }
})
@Injectable()
export class AddMissedAcsCodesActionService implements DwActionHandler {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private planofActionGeneratorService: PlanOfActionGeneratorService,
        private dwModalService: DwModalService

    ) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        const result = new DwActionResult();

        const planOfAction = actionState.formApi.getFormData<PlanOfActionEntity>();



        return this.addMissedCodes(actionState, planOfAction);
    }

    addMissedCodes(actionState: DwMdFormActionState<any>, planOfAction: PlanOfActionEntity): Observable<DwActionResult> {
        
        return this.planofActionGeneratorService.addMissedCodesToSections(planOfAction)
            .pipe(map((missedCodeAddResult) =>{
                const result: DwActionResult = {
                    success: true
                };
                const messages : string[] = [];

                if (missedCodeAddResult.codesAdded > 0) {
                    messages.push(`Added ${missedCodeAddResult.codesAdded} ACS Codes`);

                    actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction);
                    actionState.formApi.formGroup.patchValue(planOfAction);
                    actionState.formApi.patchFormData(planOfAction);
                }

                if (missedCodeAddResult.missingCodeCount > 0) {
                    messages.push(`Could not add ${missedCodeAddResult.codesAdded} ACS Codes because the sections were missing.  Please add all sections, then re-add missing ACS Codes`);
                }

                if (messages.length==0) {
                    messages.push('All missing ACS Codes have already been added to sections');
                }

                result.message = messages.join('\n');

                return result;
            }));


    }

    
}