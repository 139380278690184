<ng-container *ngIf="setupComplete">
    <div dwRow *ngIf="options?.allowQuestionSelect">
        <div dwCol="6">
            <label class="label">Select Questions <i class="fa fa-info-circle"
                    title="Show questions from the Question bank for each ACS Code"></i></label>
                <dw-yesno [(ngModel)]="options.useQuestionBank" (ngModelChange)="onOptionChange()"></dw-yesno>
        </div>
        <div dwCol="6">
            <label class="label">Limit to Missed Codes<i class="fa fa-info-circle"
                    title="Limit the codes to only codes added to the Missed ACS Codes"></i></label>
                <dw-yesno [(ngModel)]="showOnlyMissedAcsCodes" (ngModelChange)="onOptionChange()"></dw-yesno>
        </div>
    </div>
    <dw-meta-data-grid *ngIf="options.useQuestionBank == false" [item]="aosCodesItem" configKeyPart="select-codes"
        [filters]="codesGridFilters" allowConfig="true" [preferences]="codesGridPreferences"
        (gridEvent)="onCodeGridEvent($event)" [gridCallbacks]="gridCallbacks"></dw-meta-data-grid>
    <dw-meta-data-grid *ngIf="options.useQuestionBank == true" [item]="aosCodesItem" configKeyPart="select-questions"
        [filters]="questionGridFilters" allowConfig="true" [preferences]="questionsGridPreferences"
        (gridEvent)="onQuestionGridEvent($event)" [gridCallbacks]="gridCallbacks"></dw-meta-data-grid>
</ng-container>

<!-- Filters: {{codesGridFilters | json}} -->