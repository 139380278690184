import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DwAuthGuardService, DwLoginComponent, DwRouteGuardUtil, DwSsoLoginComponent, UserProfileComponent, DwUserRegistrationComponent, DevwareGetStartedComponent } from '@devwareapps/devware-cap';

import { HomeComponent } from './features/shared/pages/home/home.component';
import { AnnonymousHomeViewComponent } from './features/homepage/components/annonymous-home-view/annonymous-home-view.component';
import { PilexosHomeComponent } from './features/homepage/components/home-page/home-page.component';
import { ExaminerPublicProfileComponent } from './features/examiner/components/examiner-public-profile/examiner-public-profile.component';

var routes = [
    {
        path: '',
        component: PilexosHomeComponent,
        data: {
            display: 'Home',
            iconCssClass: 'fa fa-menu-icon fa-home',
            routeOrder: 5,
            hideBreadcrumbParent: true,
            excludeFromMainMenu: false,
            showInMenu: true,
            highlightOnExactMatch: true,
            referenceKey: 'home',
        }
    }, 
    {
        path: 'login',
        component: DwLoginComponent,
        data: {
            routeId: 'login',
            display: 'Login',
            iconCssClass: 'fa fa-menu-icon fa-power-off',
            showInMenu: false,
            referenceKey: 'login'
        }
    },
    {
        path: 'register',
        component: DwUserRegistrationComponent,
        //canActivate: [DwAuthGuardService],
        data: {
            routeId: 'register',
            display: 'Register',
            showInMenu: false,
            routeOrder: 16,
            referenceKey: 'register',
        }
    },
    {
        path: 'examiner-profile/:examinerKey/public',
        component: ExaminerPublicProfileComponent,
        //canActivate: [DwAuthGuardService],
        data: {
            routeId: 'examiner-public-profile',
            display: 'Examiner Profile',
            showInMenu: false,
            routeOrder: 99,
            referenceKey: 'examiner-public-profile',
        }
    },
    {
        path: "**",
        redirectTo: '',
        data: { 
            showInMenu: false
        }
    },
];


// Add Dw Auth Guard to all routes (uses the requireAuthentication and CanAcessPermission data properties)
DwRouteGuardUtil.secureRoutes(routes, DwAuthGuardService);

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
