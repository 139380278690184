import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DwSectionBaseComponent, DwMetaDataFormApi, DwExpressionService, DwUiConfigRegistryService, DwUIMetaDataConfigToken, DwUIMetaDataConfig, DwMetaDataServiceToken, DwMetaDataService, DwMetaDataFormStateService, DwComponent, DwComponentType, DwLookupInfo, DwContainerOptions, DwExpandCollapseStyle, DwExpandCollapseLocation, sortArrayByField, isFirstChange } from '@devwareapps/devware-cap';
import { PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionSectionQuestionEntity, AcsCodesLookupQueryEntity, AppMetaDataItemNames, AppMetaDataLookups, PlanOfActionSectionTypeAllItems, PlanOfActionSectionTypeEntity, AcsTestEntity, AcsTestPortionEntity } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem, AcsCodeStatuses } from '../../../plan-of-action/models/acs-code-items.model';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { FormControl } from '@angular/forms';
import { GenerateQuestionsActionService } from '../../../plan-of-action/actions/generate-questions-action.service';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';
import { AcsReferenceInfo } from '../../models/acs-reference-info.model';
import { PlanOfActionUtilService } from '../../services/plan-of-action-util.service';

@DwComponent({
  key: 'plan-of-action-section',
  name: 'Plan of Action Section',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PlanOfActionSection,
  isGlobal: false
})
@Component({
  selector: 'app-plan-of-action-section',
  templateUrl: './plan-of-action-section.component.html',
  styleUrls: ['./plan-of-action-section.component.scss']
})
export class PlanOfActionSectionComponent extends DwSectionBaseComponent {
  static expandedSections: { [prop: number]: boolean } = {};
 
  formApi: DwMetaDataFormApi;

  @Input() planOfAction: PlanOfActionEntity;
  @Input() planOfActionSection: PlanOfActionSectionEntity;
  @Input() acsReferenceInfo: AcsReferenceInfo;
  @Input() acsCodeStatuses: AcsCodeStatuses;
  @Input() acsTestPortion: AcsTestPortionEntity;

  @Output() planOfActionSectionChange = new EventEmitter<PlanOfActionSectionEntity>();

  @Input() directMode: boolean = false;

  acsCodes: AcsCodesStorageItem;
  @Input() collapsed: boolean = true;
  @Output() collapsedChange = new EventEmitter<boolean>();

  

  showAcsCode: boolean = true;
  showQuestion: boolean = true;

  @Input() optionsShowQuestionForAcsCode: boolean;


  rootFormGroup: any;
  hideLabels: boolean = false;
  allowTitleEdit: boolean = false;
  editingTitle: boolean = false;

  planOfActionSectionQuestionFC: FormControl;

  planOfActionSectionQuestions: PlanOfActionSectionQuestionEntity[] = [];

  currentQuestions: PlanOfActionSectionQuestionEntity[] = [];

  containerOptions: DwContainerOptions;
  //sectionTypes: PlanOfActionSectionTypeEntity[];
  sectionTitle: string;
  currentAcsCodes: AcsCodesLookupQueryEntity[];
  
  sectionType: PlanOfActionSectionTypeEntity;
  missingAcsCodeImageUrl: string;

  // @Input() acsTest: AcsTestEntity;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private generateQuestionsActionService: GenerateQuestionsActionService,
    private planofActionGeneratorService: PlanOfActionGeneratorService,
    private planofActionUtilService: PlanOfActionUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
    this.missingAcsCodeImageUrl = this.planofActionUtilService.getMissedAcsCodeImageUrl();
  }


  ngOnInit(): void {
    // this.loadReferenceData();
    this.setupDataWatch();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!isFirstChange(simpleChanges)) {
      this.checkForDataChange();
    }
  }

  collapsedChanged(collapsed: boolean) {
    this.collapsedChange.emit(collapsed);
  }

  editSectionTitle() {
    this.editingTitle = true;
  }

  onSectionTitleChange() {
    this.planOfActionSection.SectionName = this.sectionTitle;
    this.editingTitle = true;
  }

  addQuestion() {
    this.planOfActionSectionQuestions.push({
      _itemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
      PlanOfActionSectionQuestionId: this.getNextQuestionId(),
    });

    this.onQuestionsReorder();

    this.setupCurrentQuesitons();
  }

  nextKeyValue = 0;

  getNextQuestionId(): number {
    // Need to determine what keys values have been used
    if (this.planOfActionSectionQuestions.length == 0) {
      this.nextKeyValue--;

      return this.nextKeyValue;
    }

    let minKeyValue = 0;

    for (let question of this.planOfActionSectionQuestions) {
      const keyValue = question.PlanOfActionSectionQuestionId;

      if (keyValue < minKeyValue) {
        minKeyValue = keyValue;
      }
    };

    this.nextKeyValue = minKeyValue - 1;

    return this.nextKeyValue;
  }

  addAcsCodes() {
    this.generateQuestionsActionService.showAcsCodeSelectionModal(this.planOfAction, this.planOfActionSection)
      .subscribe(modalResult => {
        if (modalResult.cancelled) {
          modalResult.cancelled = true;

          return;
        }

        if (modalResult.data) {
          this.planofActionGeneratorService.generateQuestions(this.planOfActionSection, this.planOfActionSectionQuestions, modalResult.data.GenerateCodesOptions);



          this.setupCurrentQuesitons();
          this.onQuestionsReorder();
        }
      });
  }

  onQuestionChange(question: PlanOfActionSectionQuestionEntity) {
    this.onQuestionsReorder();
    //this.planOfActionSectionQuestionFC.setValue(this.planOfActionSectionQuestions);
  }

  onQuestionRemoved(question: PlanOfActionSectionQuestionEntity) {
    question._isDeleted = true;
    
    this.planOfActionSectionChange.emit(this.planOfActionSection);
  }

  onQuestionsReorder() {
    let i = 0;

    for(let question of this.currentQuestions) {
      if (question._isDeleted) {
        continue;
      }
      i++;
      question.QuestionOrder = i;
    }

    this.setupCurrentQuesitons();

    if (this.planOfActionSectionQuestionFC) {
      this.planOfActionSectionQuestionFC.setValue(this.planOfActionSectionQuestions);
    }

    this.planOfActionSectionChange.emit(this.planOfActionSection);
  }

  setupCurrentQuesitons() {
    this.currentQuestions = sortArrayByField(this.planOfActionSectionQuestions.filter(x => !x._isDeleted).map(q => this.mapQuestionOrder(q)), 'QuestionOrder');
  }

  mapQuestionOrder(question: PlanOfActionSectionQuestionEntity): any {
   if(question.QuestionOrder == null) {
     question.QuestionOrder = 9999;
   }

   return question;
  }

  setupDataWatch() {
    if (this.directMode) {
      this.checkForDataChange();
      return;
    }

    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {
    if (!this.directMode) {
      this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();

      const planOfActionSection = this.formGroup.getRawValue();
      this.planOfActionSection = this.planOfAction.PlanOfActionSection.find(x => x.PlanOfActionSectionId == planOfActionSection.PlanOfActionSectionId);
    }

    if (!this.planOfAction || !this.planOfActionSection) {
      return;
    }

    this.setupSection();

    this.setupAcsCodes();
    this.setupContainer();
  }

  setupSection() {
    if (this.planOfActionSectionQuestionFC) {
      return;
    }

    this.planOfActionSectionQuestions = sortArrayByField(this.planOfActionSection.PlanOfActionSectionQuestion || [], 'QuestionOrder');

    this.setupCurrentQuesitons();

    if (!this.directMode) {
      this.planOfActionSectionQuestionFC = new FormControl(this.planOfActionSectionQuestions);

      this.formGroup.addControl('PlanOfActionSectionQuestion', this.planOfActionSectionQuestionFC);
    }
  }

  private setupContainer() {
    this.sectionType = this.acsReferenceInfo?.sectionTypes?.find(x => x.PlanOfActionSectionTypeId == this.planOfActionSection?.PlanOfActionSectionTypeId);

    if (!this.sectionType) {
      return;
    }

    this.showQuestion = this.planOfAction.OptionsShowQuestionForAcsCode;

    this.sectionTitle = this.planOfActionRepositoryService.getSectionTitle(this.planOfActionSection, this.acsReferenceInfo);

    switch (this.planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.Manual:
        this.allowTitleEdit = true;
        this.sectionTitle = this.planOfActionSection.SectionName;
        this.showAcsCode = false;
        this.showQuestion = true;
        break;
      default:
        this.showAcsCode = true;
    }

    this.containerOptions = {
      useContainerStyling: true,
      styleName: 'TITLE_ONLY_LINE',
      // expandCollapseStyle: DwExpandCollapseStyle.chevron,
      // expandCollpaseLocation: DwExpandCollapseLocation.left,
      containerAllowCollapse: true,
      //containerCollapseDefault: true,
      containerHeaderForegroundColor: this.acsTestPortion?.HeaderClass || this.sectionType.AcsTestPortion.HeaderClass,
      containerHeaderIcon: this.acsTestPortion?.IconClass || this.sectionType.AcsTestPortion.IconClass,
      showTitle: true,
    }
  }


  private setupAcsCodes() {
    if (!this.planOfAction || !this.planOfActionSection) {
      return;
    }

    if (this.acsCodes) {
      return;
    }

    if (!this.planOfActionSection.AcsAreaOfOperationId) {
      return;
    }

    this.acsCodes = this.planOfActionRepositoryService.getAcsCodesFromreference(this.acsReferenceInfo, this.planOfActionSection.AcsAreaOfOperationId, this.planOfActionSection.AcsAreaOfOperationTaskId);

    const blankCode: AcsCodesLookupQueryEntity = {
      _itemName: '',
      AcsCodeId: null,
      AcsCode: ' ',
    };

    this.currentAcsCodes = [blankCode, ...this.acsCodes?.acsCodes || []];

    this.setupContainer();
  }

}
