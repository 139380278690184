import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestStatusAllItems, CheckRideRequestSubStatusAllItems, PlanOfActionDetailsQueryEntity, PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";

@DwAction({
    keyName: 'poa-save-and-execute-action',
    display: 'Save and Execute Plan Of Action',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        useParentDisplay: false,
        buttonText: 'Execute',
        buttonClass: 'btn btn-warning mr-1 mt-1',
        iconClass: 'fa fa-plane'
    },
    configData: {
        requireValidation: true,
        redirectAfter: false,
        actionMessage: 'Saving...',
    }
})
@Injectable()
export class POASaveAndExecuteActionService implements DwActionHandler {

    constructor(private dwRoutingService: DwRoutingService) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        // if (actionState.formApi.formGroup.pristine) {
        //     const planOfAction  = actionState.formApi.getFormData<PlanOfActionEntity>();

        //     this.redirectToExecute(planOfAction);

        //     return of({ success: true });
        // }

        return actionState.formApi.saveData(true)
            .pipe(map((result) => {
                const planOfAction: PlanOfActionEntity = result.saveResult;
                
                if (result.success) {
                    this.redirectToExecute(planOfAction);
                }

                return result;
            }));
    }

    redirectToExecute(planOfAction: PlanOfActionEntity): void {
        const url = `/plan-of-action/plans/edit/${planOfAction.PlanOfActionId}/execute`;

        this.dwRoutingService.navigateToUrl(url, null);
    }

}