import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwMessageSeverity, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AcsCodeLookupRequestEntity, ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, PlanOfActionEntity, PlanOfActionSectionEntity } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../../plan-of-action/components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../../plan-of-action/components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";

@DwAction({
    keyName: 'add-required-acs-codes-action',
    display: 'Add Missed ACS Codes',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfAction, AppMetaDataItemNames.ApplicantAcsWrittenResult],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        buttonClass: 'btn btn-warning mr-1 mt-1',
        buttonText: 'Add ACS Codes',
        iconClass: 'fa fa-tasks-alt'
    },
    configData: {
        requireValidation: false,
        redirectAfter: false,
        actionMessage: '',
    }
})
@Injectable()
export class AddRequiredAcsCodesActionService implements DwActionHandler {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private planofActionGeneratorService: PlanOfActionGeneratorService,
        private dwModalService: DwModalService

    ) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        const result = new DwActionResult();

        switch (actionState.formApi.item) {
            case AppMetaDataItemNames.PlanOfAction:
                const planOfAction = actionState.formApi.getFormData<PlanOfActionEntity>();

                return this.showPlanOfActionAcsSelectionModal(actionState, planOfAction);
            case AppMetaDataItemNames.CheckRideRequest:
                const checkRideRequest = actionState.formApi.getFormData<CheckRideRequestEntity>();

                return this.showCheckrideAcsSelectionModal(actionState, checkRideRequest);
            case AppMetaDataItemNames.AcsCodeLookupRequest:
                const lookupRequest = actionState.formApi.getFormData<AcsCodeLookupRequestEntity>();

                return this.showCheckrideAcsSelectionModal(actionState, lookupRequest, 30);
        }
    }

    showCheckrideAcsSelectionModal(actionState: DwMdFormActionState, checkRideRequest: CheckRideRequestEntity | AcsCodeLookupRequestEntity, limitCodeCount?: number): Observable<DwActionResult> {

        return this.showSelectAcsModal(actionState, checkRideRequest.ApplicantAcsWrittenResult)
            .pipe(map(result => {
                if (!result.cancelled) {
                    if (limitCodeCount) {
                        if (checkRideRequest.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode.length > limitCodeCount) {
                            //actionState.formApi.setFormMessage({ messageBody: `You can add a maximum of ${limitCodeCount}`, severity: DwMessageSeverity.error });

                            result.message = { messageBody: `You can add a maximum of ${limitCodeCount}, some codes were not added!`, severity: DwMessageSeverity.warning }
                            checkRideRequest.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode.length = limitCodeCount;
                        }
                    }

                    actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, checkRideRequest);
                    actionState.formApi.formGroup.patchValue(checkRideRequest);
                    actionState.formApi.setFormData(checkRideRequest);

                    return result;
                }
            }));

    }

    showPlanOfActionAcsSelectionModal(actionState: DwMdFormActionState, planOfAction: PlanOfActionEntity): Observable<DwActionResult> {

        if (!planOfAction.ApplicantAcsWrittenResult?.AcsTestId) {
            planOfAction.ApplicantAcsWrittenResult.AcsTestId = planOfAction.AcsTestId;
        }

        const acsCodeStatuses = this.planOfActionRepositoryService.getAcsCodeStatus(planOfAction);

        return this.showSelectAcsModal(actionState, planOfAction.ApplicantAcsWrittenResult, acsCodeStatuses)
            .pipe(tap(result => {
                if (!result.cancelled) {
                    // Plan of action's reference is updated so we can just use that
                    actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction);
                    actionState.formApi.formGroup.patchValue(planOfAction);
                    actionState.formApi.setFormData(planOfAction);
                }
            }))

    }

    showSelectAcsModal(actionState: DwMdFormActionState<any>, applicantWritten: ApplicantAcsWrittenResultEntity, acsCodeStatuses?: AcsCodeStatuses): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig<AcsCodeSelectionModalOptions>();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.large;

        modalConfig.component = GenerateQuestionsModalComponent;

        modalConfig.title = 'Add ACS Codes';
        modalConfig.data = {
            allowQuestionSelect: false,
            acsTestId: applicantWritten.AcsTestId,
            acsCodeStatuses: acsCodeStatuses
            //planOfAction: planOfAction,
        };

        return this.dwModalService.showModal<any, AcsCodeSelectionModalResult>(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwActionResult();

                if (result.cancelled) {
                    result.cancelled = true;

                    return result;
                }

                if (modalResult.data) {
                    if (modalResult.data.GenerateCodesOptions.acsCodes?.length > 0) {
                        this.planofActionGeneratorService.addRequiredAcsCodes(applicantWritten, modalResult.data.GenerateCodesOptions);
                    }

                    // // Plan of action's reference is updated so we can just use that
                    // actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction); 
                    // actionState.formApi.formGroup.patchValue(planOfAction);
                    // actionState.formApi.setFormData(planOfAction);
                }

                return result;
            }));
    }


    // showPlanOfActionModal(actionState: DwMdFormActionState, planOfAction: PlanOfActionEntity): Observable<DwActionResult> {
    //     let modalConfig = new DwModalConfig<AcsCodeSelectionModalOptions>();

    //     modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

    //     modalConfig.allowFullScreen = true;
    //     modalConfig.modalSize = DwModalSize.large;

    //     modalConfig.component = GenerateQuestionsModalComponent;

    //     modalConfig.title = 'Add Missed AcsCodes';
    //     modalConfig.data = {
    //         allowQuestionSelect: false,
    //         acsTestId: planOfAction.AcsTestId,
    //         acsCodeStatuses: this.planOfActionRepositoryService.getAcsCodeStatus(planOfAction),
    //         //planOfAction: planOfAction,
    //     };

    //     return this.dwModalService.showModal<any, AcsCodeSelectionModalResult>(modalConfig)
    //         .pipe(map(modalResult => {
    //             const result = new DwActionResult();

    //             if (result.cancelled) {
    //                 result.cancelled = true;

    //                 return result;
    //             }

    //             if (modalResult.data) {
    //                 if (modalResult.data.GenerateCodesOptions.acsCodes?.length > 0) {
    //                     this.planofActionGeneratorService.addRequiredAcsCodes(planOfAction, modalResult.data.GenerateCodesOptions);
    //                 }

    //                 // Plan of action's reference is updated so we can just use that
    //                 actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction);
    //                 actionState.formApi.formGroup.patchValue(planOfAction);
    //                 actionState.formApi.setFormData(planOfAction);
    //             }

    //             return result;
    //         }));
    // }
}