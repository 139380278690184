import { Injectable } from "@angular/core";
import { AcsTestPortionEntity, PlanOfActionEntity, PlanOfActionQuestionResultEntity, PlanOfActionSectionEntity } from "../../../meta-data/app-meta-data.service";
import { DwContainerOptions, DwLookupInfo } from "@devwareapps/devware-cap";

@Injectable({ providedIn: 'root' })
export class PlanOfActionUtilService {

    buildAcsTestDetails(planOfAction: PlanOfActionEntity, planOfActionSections: PlanOfActionSectionEntity[], questionResults:PlanOfActionQuestionResultEntity[], acsTestPortions: AcsTestPortionEntity[]): AcsTestPortionResult[] {
        const testDetails: AcsTestPortionResult[] = [];

        for (let acsTestPortion of acsTestPortions) {
            const testDetail = this.buildAcsTestDetail(planOfAction, planOfActionSections, questionResults, acsTestPortion);

            if (testDetail) {
                testDetails.push(testDetail);
            }
        }

        return testDetails;
    }

    private buildAcsTestDetail(planOfAction: PlanOfActionEntity,  planOfActionSections: PlanOfActionSectionEntity[], questionResults:PlanOfActionQuestionResultEntity[],acsTestPortion: AcsTestPortionEntity): AcsTestPortionResult {
        const result: AcsTestPortionResult = {
            acsTestPortion: acsTestPortion,
            sections: planOfActionSections.filter(section => section.AcsTestPortionId === acsTestPortion.AcsTestPortionId),
            questionCount: 0,
            questionsCompleted: 0,
            percentComplete: 0,
            sectionCount: 0,
            sectionsCompleted: 0,
            containerOptions: this.buildAcsPortionContainer(acsTestPortion)
        };

        if (result.sections.length === 0) {
            return null;
        }

        let sectionCompleted = 0;
        for (let section of result.sections) {
            const sectionQuestionResults = questionResults.filter(questionResult => section.PlanOfActionSectionQuestion.find(q => q.PlanOfActionSectionQuestionId== questionResult.PlanOfActionSectionQuestionId));

            result.questionCount += section.PlanOfActionSectionQuestion.length;
            result.questionsCompleted += sectionQuestionResults.filter(qr => qr.QuestionOutcomeId).length;

            if (result.questionCount>0) {
                result.percentComplete = Math.round((result.questionsCompleted * 100) / result.questionCount);
            } 

            if (result.percentComplete === 100) {
                sectionCompleted++;
            }
        }

        result.sectionCount = result.sections.length;
        result.sectionsCompleted = sectionCompleted;

        return result;
    }

    
  private buildAcsPortionContainer(acsTestPortion: AcsTestPortionEntity):DwContainerOptions {
    return {
      useContainerStyling: true,
      //styleName: 'TITLE_ONLY_LINE',
      // expandCollapseStyle: DwExpandCollapseStyle.chevron,
      // expandCollpaseLocation: DwExpandCollapseLocation.left,
      containerAllowCollapse: false,
      //containerCollapseDefault: true,
      containerHeaderBackgroundColor: acsTestPortion.HeaderClass,
      containerHeaderIcon: acsTestPortion.IconClass,
      showTitle: true,
    }
  }

  getMissedAcsCodeImageUrl() : string {

    return `${location.origin}/assets/img/missed-acs-code.png`;
  }


}

export interface AcsTestPortionResult {
    acsTestPortion: AcsTestPortionEntity;

    sections: PlanOfActionSectionEntity[];

    questionCount: number;
    questionsCompleted: number;

    percentComplete: number;

    sectionCount: number;
    sectionsCompleted: number;

    containerOptions: DwContainerOptions;
}