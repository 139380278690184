import { Component, Inject, OnInit } from '@angular/core';
import { DwSectionBaseComponent, DwMetaDataFormApi, DwExpressionService, DwUiConfigRegistryService, DwUIMetaDataConfigToken, DwUIMetaDataConfig, DwMetaDataServiceToken, DwMetaDataService, DwMetaDataFormStateService, DwComponent, DwComponentType, DwLookupInfo, DwContainerOptions, DwExpandCollapseStyle, DwExpandCollapseLocation, sortArrayByField, DwMdFormContextInfo } from '@devwareapps/devware-cap';
import { PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionSectionQuestionEntity, AcsCodesLookupQueryEntity, AppMetaDataItemNames, AppMetaDataLookups, PlanOfActionSectionTypeAllItems, PlanOfActionSectionTypeEntity, AcsTestPortionAllItems, AcsTestEntity, AppMetaData, AcsTestPortionEntity } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem, AcsCodeStatuses } from '../../../plan-of-action/models/acs-code-items.model';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { GenerateQuestionsActionService } from '../../../plan-of-action/actions/generate-questions-action.service';
import { PlanOfActionGeneratorService } from '../../../plan-of-action/services/plan-of-action-generator.service';
import { PlanOfActionModalService } from '../../services/plan-of-action-modal.service';
import { AcsReferenceInfo } from '../../models/acs-reference-info.model';

@DwComponent({
  key: 'plan-of-action-sections',
  name: 'Plan of Action Sections',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PlanOfAction,
  isGlobal: false
})
@Component({
  selector: 'app-plan-of-action-sections',
  templateUrl: './plan-of-action-sections.component.html',
  styleUrls: ['./plan-of-action-sections.component.scss']
})
export class PlanOfActionSectionsComponent extends DwSectionBaseComponent {
  static expandedSections: { [prop: number]: boolean } = {};

  formApi: DwMetaDataFormApi;
  planOfAction: PlanOfActionEntity;
  lastPlanOfAction: PlanOfActionEntity;
  originalPlanOfAction: PlanOfActionEntity;
  acsTest: AcsTestEntity;

  planOfActionSectionsFC: AbstractControl;
  currentSections: PlanOfActionSectionEntity[];

  selectedSectionIndex: number = 0;

  //acsTestPortionId: number; // = AcsTestPortionAllItems.PreQualifications;

  //containerOptions: DwContainerOptions;
  activeTabIndex: number = 0;
  manageSections: boolean = false;
  acsReferenceInfo: AcsReferenceInfo;
  acsTestPortions: AcsTestPortionEntity[];


  currentAcsTestPortion: AcsTestPortionEntity;
  acsCodeStatuses: AcsCodeStatuses;


  // sectionTypes: PlanOfActionSectionTypeEntity[];

  onSectionCollapsedChange(collapsed: boolean, index: number) {
    //console.log('Section collapsed change', collapsed, index);
    if (collapsed) {
      if (this.selectedSectionIndex == index) {
        this.selectedSectionIndex = -1;
      }
    } else {
      this.selectedSectionIndex = index;
    }
  }

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private generateQuestionsActionService: GenerateQuestionsActionService,
    private planofActionGeneratorService: PlanOfActionGeneratorService,
    private planOfActionModalService: PlanOfActionModalService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.loadReferenceData();
    this.setupDataWatch();

    //console.log('Section created');
  }

  onSectionsReorder(sections: PlanOfActionSectionEntity[]) {

    let order = 1;
    for(const section of sections) {

      section.SectionOrder = order;
      order++;
    }
    // this.planOfAction.PlanOfActionSection = sections;

    // this.planOfActionSectionsFC.setValue(sections);
  }

  onSectionRemoved(section: PlanOfActionSectionEntity) {
    section._isDeleted = true;
  }

  startManageSections() {
    this.manageSections = true;
  }

  completeManageSections() {
    this.manageSections = false;
  }

  manageSectionsChange() {
    if (!this.manageSections) {
      this.selectedSectionIndex = 0;
      this.setupSections();
    }
  }


  planOfActionSectionChange(section: PlanOfActionSectionEntity) {

    this.updateSections(this.planOfAction.PlanOfActionSection);
    //    this.planOfActionRepositoryService.savePlanOfActionSection(section);
  }

  handleTabChange(index: number) {
    this.currentAcsTestPortion = this.acsTestPortions[index];
    //const acsTestPortionId = this.acsTestPortions[index]?.Id;

    this.setAcsTestPortion(this.currentAcsTestPortion);
  }

  addSections() {
    this.planOfActionModalService.showGenerateSectionsModal(this.planOfAction, this.currentAcsTestPortion?.AcsTestPortionId)
      .subscribe(result => {
        if (result.cancelled) {
          return;
        }

        this.updateSections(result.data.PlanOfActionSection);
      });
  }

  addManualSection() {
    const section: PlanOfActionSectionEntity = {
      _itemName: AppMetaDataItemNames.PlanOfActionSection,
      PlanOfActionSectionId: this.getNextSectionId(),
      PlanOfActionId: this.planOfAction.PlanOfActionId,
      PlanOfActionSectionTypeId: PlanOfActionSectionTypeAllItems.Manual,
      AcsTestPortionId: this.currentAcsTestPortion?.AcsTestPortionId,
      SectionName: 'New Section',
      PlanOfActionSectionQuestion: [],
//      SectionOrder: this.currentSections.length + 1 // Handled in reorder
    };

    this.onSectionsReorder(this.currentSections);

    this.planOfAction.PlanOfActionSection.push(section);

    this.updateSections(this.planOfAction.PlanOfActionSection);
  }

  private nextKeyValue: number = 0;

  getNextSectionId(): number {
    // Need to determine what keys values have been used
    if (this.planOfAction.PlanOfActionSection.length == 0) {
      this.nextKeyValue--;

      return this.nextKeyValue;
    }

    let minKeyValue = 0;

    for (let section of this.planOfAction.PlanOfActionSection) {
      const keyValue = section.PlanOfActionSectionId;

      if (keyValue < minKeyValue) {
        minKeyValue = keyValue;
      }
    };

    this.nextKeyValue = minKeyValue - 1;

    return this.nextKeyValue;
  }

  private updateSections(planOfActionSections: PlanOfActionSectionEntity[]) {
    this.planOfAction.PlanOfActionSection = planOfActionSections;

    this.planOfActionSectionsFC.setValue(this.planOfAction.PlanOfActionSection);

    this.setupSections();
  }

  setAcsTestPortion(acsTestPortion : AcsTestPortionEntity) {
    this.currentAcsTestPortion = acsTestPortion;

    this.setupSections();

    //this.manageSections = (this.currentSections.length == 0)
  }

  loadReferenceData() {
    this.planOfActionRepositoryService.getAcsTestPortions()
      .subscribe(acsTestPortions => {
        this.acsTestPortions = acsTestPortions;

        this.currentAcsTestPortion =this.acsTestPortions[0];
      });
    // this.dwMetaDataService.getLookup(AppMetaDataLookups.AcsTestPortionAllItems)
    //   .subscribe(acsTestPortions => {
    //     this.acsTestPortions = acsTestPortions;
    //   });
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }

  private checkForDataChange() {
    this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();

    if (!this.planOfActionSectionsFC) {
      this.planOfActionSectionsFC = this.formGroup["PlanOfActionSection"];

      if (!this.planOfActionSectionsFC) {
        this.planOfActionSectionsFC = new FormControl([]);
        this.formGroup.addControl("PlanOfActionSection", this.planOfActionSectionsFC);
      }

      this.planOfActionSectionsFC.setValue(this.planOfAction.PlanOfActionSection);
    }

    // Link written ACS code if it exists
    this.checkWrittenInfo();

    this.setupSections();
  }

  private checkWrittenInfo() {
    if (!this.planOfAction.ApplicantAcsWrittenResultId || (this.lastPlanOfAction && this.lastPlanOfAction?.CheckRideRequestId != this.planOfAction.CheckRideRequestId)) {
      this.updateWrittenInfo();
    }

    this.lastPlanOfAction = this.planOfAction;

    if (!this.originalPlanOfAction) {
      this.originalPlanOfAction = this.planOfAction;
    }
  }

  updateWrittenInfo() {
    if (!this.planOfAction.CheckRideRequestId) {
      if (!this.originalPlanOfAction) {
        return;
      }

      if(this.originalPlanOfAction.CheckRideRequestId) {
        // We need to clear the written result
        this.planOfAction.ApplicantAcsWrittenResultId = -1;
        this.planOfAction.ApplicantAcsWrittenResult = AppMetaData.ApplicantAcsWrittenResult.CreateEntity();
        this.planOfAction.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode = [];

        this.updatePlanofActionForm();
      }


      return;
    }

    this.planOfActionRepositoryService.loadCheckrideAndAcsTestResult(this.planOfAction.CheckRideRequestId)
      .subscribe(checkRideRequest => {
        // If no written result, then we don't do anything
        if (!checkRideRequest.ApplicantAcsWrittenResultId) {
          return;
        }

        this.planOfAction.ApplicantAcsWrittenResultId = checkRideRequest.ApplicantAcsWrittenResultId;
        this.planOfAction.ApplicantAcsWrittenResult = checkRideRequest.ApplicantAcsWrittenResult;

        this.updatePlanofActionForm();
      });
  }

  private updatePlanofActionForm() {
    this.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, this.planOfAction);
    this.formApi.formGroup.patchValue(this.planOfAction);
    this.formApi.setFormData(this.planOfAction);
  }

  firstTime = true;
  setupSections() {
    if (!this.planOfAction || !this.acsTestPortions) {
      return;
    }

    if (this.acsTest == null) {
      this.loadAcsTest();
    }

    this.acsCodeStatuses = this.planOfActionRepositoryService.getAcsCodeStatus(this.planOfAction);

    if (!this.currentAcsTestPortion?.AcsTestPortionId) {
      const acsTestPortion = this.acsTestPortions[0]
      this.setAcsTestPortion(acsTestPortion);
    }

    this.currentSections = this.planOfAction?.PlanOfActionSection.filter(x => !x._isDeleted && x.AcsTestPortionId == this.currentAcsTestPortion?.AcsTestPortionId);

    if (this.currentSections.length == 0 && this.firstTime) {
      this.firstTime = false;
      this.startManageSections();
    }

    this.onSectionsReorder(this.currentSections);
  }

  loadAcsTest() {
    this.planOfActionRepositoryService.getAcsReference(this.planOfAction.AcsTestId)
      .subscribe(acsReferenceInfo => {
        this.acsReferenceInfo = acsReferenceInfo;
      });
  }

}

