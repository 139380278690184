<div class="question-container" [ngClass]="questionClass">
    <div class="row-one">
        <div class="column-one">
            <div class="acs-code" *ngIf="showAcsCode">
                <span *ngIf="acsCode" [title]="acsCode?.AscCodeDescription">
                    {{acsCode?.AcsCode}}  
                    <!-- <i class="fa fa-times dw-red" title="Missed ACS Code" [hidden]="!isMissedCode"></i> -->
                    <img [src]="missingAcsCodeImageUrl" *ngIf="isMissedCode" class="missed-acs-code" title="Missed ACS Code"/>
                </span>
            </div>
            <div class="question-result" *ngIf="showAcsCode">
                <dw-radio-lookup [(ngModel)]="result.QuestionOutcomeId" (ngModelChange)="onChange()"
                    [lookupId]="outcomeLookupId">
                </dw-radio-lookup>
            </div>
            <div class="question-result" *ngIf="!showAcsCode">
                <input type="checkbox" [(ngModel)]="checkedValue" (ngModelChange)="updateCheckedValue($event)" value="1">
            </div>
        </div>
        <div class="column-two">
            <div class="question" [ngClass]="{ 'question-acs': this.showAcsCode }">
                <dw-html-view [ngModel]="question?.QuestionHtml" *ngIf="showQuestion"></dw-html-view>
                <div *ngIf="!showQuestion || acsCode?.AscCodeDescription">ACS: {{acsCode?.AscCodeDescription}}</div>
            </div>
            <div class="question-notes" *ngIf="showNotes">
                <input type="text" [(ngModel)]="result.Notes" (ngModelChange)="onChange()" placeholder="Notes"
                    class="form-control hide-print">
                <input type="text" [(ngModel)]="result.Notes" (ngModelChange)="onChange()"
                    class="form-control show-print">
            </div>
        </div>
    </div>
</div>