import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DwButtonCommand, DwButtonConfig, DwComponent, DwComponentType, DwExpressionService, DwGridActionKeys, DwGridEvent, DwGridEvents, DwGridHeightMode, DwMdFormContextInfo, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataGridComponent, DwMetaDataGridPreferences, DwMetaDataGridViewPreference, DwMetaDataService, DwMetaDataServiceToken, DwModalConfig, DwModalHandler, DwModalResult, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService, areEqual, ensureArray } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { map } from 'underscore';
import { AppMetaDataLookups, AppMetaDataItemNames, PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity, PlanOfActionSectionEntity, AppMetaData, PlanOfActionSectionTypeAllItems, AcsCodeEntity, ApplicantAcsWrittenResultEntity, CheckRideRequestEntity } from '../../../../meta-data/app-meta-data.service';
import { PlanOfActionRepositoryService } from '../../../plan-of-action/services/plan-of-action-repository.service';
import { AcsCodeStatuses } from '../../../plan-of-action/models/acs-code-items.model';
import { DwMdGridCallbacks } from '@devwareapps/devware-cap/devware-ui-metadata/grid/model/callbacks/dw-md-grid-callbacks.interface';


@DwComponent({
  key: 'required-acs-codes-grid',
  name: 'Required Acs Codes Grid',
  componentType: DwComponentType.formSection,
  parentItemName: [AppMetaDataItemNames.PlanOfActionSection, AppMetaDataItemNames.ApplicantAcsWrittenResult],
  isGlobal: false
})
@Component({
  selector: 'app-required-acs-codes-grid',
  templateUrl: './required-acs-codes-grid.component.html',
  styleUrls: ['./required-acs-codes-grid.component.scss']
})
export class RequiredAcsCodesGridComponent extends DwSectionBaseComponent {
  formApi: DwMetaDataFormApi;
  //planOfAction: PlanOfActionEntity;
  //applicantAcsWrittenResult: ApplicantAcsWrittenResultEntity;
  aosCodesItem = AppMetaDataItemNames.AcsCode;

  filters: any = {};
  acsCodeCount: number;
  codesGridPreferences: Partial<DwMetaDataGridPreferences> = {};
  acsCodeStatuses: AcsCodeStatuses;
  gridCallbacks: DwMdGridCallbacks;
  showLegend: boolean = false;

  @ViewChild('dwGrid') dwGrid: DwMetaDataGridComponent;
  mainEntity: PlanOfActionEntity | CheckRideRequestEntity;

  showGrid: boolean = false;

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }

  ngOnInit(): void {
    this.setupDataWatch();

    this.gridCallbacks = {
      getRowClass: (row)=> this.getRowClass(row.data)
    }
  }

  private getRowClass(acsCode: AcsCodeEntity) {
    if (!acsCode) {
      return;
    }

    if (this.mainEntity._itemName != AppMetaDataItemNames.PlanOfAction) {
      return;
    }

    const acsStatus = this.acsCodeStatuses.codesById[acsCode.AcsCodeId];

    if (acsStatus) {
      if (acsStatus.missing) {
        return 'dw-red';
      }
      return 'dw-green';
    }

    return;
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }

    this.formGroup.valueChanges
      .pipe(debounceTime(100))
      .subscribe(v => {
        this.checkForDataChange();
      });
  }

  onCodeGridEvent(event: DwGridEvent) {
    console.log(event);

    if (event.actionPreference?.actionKey == 'gridPassthroughRow') {
      // Should only be a delete

      const ascCodes : AcsCodeEntity[] = ensureArray(event.eventData);

      if (ascCodes.length == 0) {
        return;
      }

      this.removeAcsCodes(ascCodes);
    }

  }

  private removeAcsCodes(ascCodes: AcsCodeEntity[]) {
    for(const ascCode of ascCodes) {
      const index = this.mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode.findIndex(c => c.AcsCodeId == ascCode.AcsCodeId);

      if (index != -1) {
        const acsCode = this.mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode[index];
        acsCode._isDeleted = true;
        //this.mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode.splice(index, 1);
      }
    }

    this.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, this.mainEntity); 
    this.formApi.formGroup.patchValue(this.mainEntity);
    this.formApi.setFormData(this.mainEntity);

    this.checkForDataChange(true);
  }

  private checkForDataChange(force: boolean = false) {
    const mainEntity = this.formApi.getFormData<PlanOfActionEntity | CheckRideRequestEntity>();
    this.mainEntity = mainEntity;

    this.showLegend = false;
    if (this.mainEntity._itemName == AppMetaDataItemNames.PlanOfAction) {
      this.showLegend = true;
    }

    // Ensure we have the ApplicantAcsWrittenResult
    if (this.mainEntity.ApplicantAcsWrittenResult == null) {
      this.mainEntity.ApplicantAcsWrittenResult = AppMetaData.ApplicantAcsWrittenResult.CreateEntity();
    }

    if (!this.mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode) {
      this.mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode = [];
    }

    if (!this.mainEntity.ApplicantAcsWrittenResult.AcsTestId) {
      this.showGrid = false;
      return;
    }

    this.showGrid = true;
    
    console.log('Required ACS Code Grid - checkForDataChange', mainEntity);
    const newAcsCodeStatus = this.planOfActionRepositoryService.getAcsCodeStatus(mainEntity);

    if (!areEqual(this.acsCodeStatuses, newAcsCodeStatus)) {
      this.acsCodeStatuses = newAcsCodeStatus;

      this.dwGrid?.gridApi?.refreshDataSource(true);
    }

    if (!force && (!mainEntity || mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode.length == this.acsCodeCount)) {
      return;
    }

    this.acsCodeCount = mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode.length || 0;

    const acsCodeIds = mainEntity.ApplicantAcsWrittenResult.ApplicantAcsWrittenResultMissedCode?.filter(c => !c._isDeleted)?.map(c => c.AcsCodeId) || [];

    if (acsCodeIds.length == 0) {
      acsCodeIds.push(-1);
    }

    this.filters = {
      AcsCodeId: {
        In: acsCodeIds
      }
    }

  }

}
