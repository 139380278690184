import { Injectable } from "@angular/core";
import { PlanOfActionEntity, PilotExaminerEntity, AcsTestEntity, AcsAreaOfOperationEntity, PlanOfActionSectionEntity, AcsAreaOfOperationTaskEntity, PlanOfActionSectionTypeAllItems, AcsCodesLookupQueryEntity, PlanOfActionSectionQuestionEntity, AppMetaDataItemNames, AppMetaData, ApplicantAcsWrittenResultEntity } from "../../../meta-data/app-meta-data.service";
import { AcsCodeStatuses, AcsCodeStorage, AcsCodesStorageItem } from "../models/acs-code-items.model";
import { GenerateSectionsOptions } from "../models/generate-sections-options.model";
import { GenerateCodesOptions } from "../models/generate-codes-options.model";
import { PlanOfActionRepositoryService } from "./plan-of-action-repository.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PlanOfActionGeneratorService {

    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService) { }


    generateSections(planOfAction: PlanOfActionEntity, options: GenerateSectionsOptions, acsTest: AcsTestEntity, allAcsCodes: AcsCodeStorage, acsTestPortionId: number) {
        planOfAction.PlanOfActionSection = planOfAction.PlanOfActionSection || [];
        if (options.clearExistingSections) {
            planOfAction.PlanOfActionSection = [];
        }

        const areaOfOperations = acsTest.AcsAreaOfOperation.filter(aoo => options.buildTaskSections || options.AreaOfOperationIds.includes(aoo.AcsAreaOfOperationId));

        for (const aoo of areaOfOperations) {
            if (options.buildTaskSections) {
                const tasks = aoo.AcsAreaOfOperationTask.filter(task => options.TaskIds.includes(task.AcsAreaOfOperationTaskId));

                for (const task of tasks) {
                    this.generateSection(planOfAction, aoo, task, options, allAcsCodes, acsTestPortionId)
                }
            } else {
                const section = this.generateSection(planOfAction, aoo, null, options, allAcsCodes, acsTestPortionId);
            }
        }
    }

    generateSection(planOfAction: PlanOfActionEntity, aoo: AcsAreaOfOperationEntity, task: AcsAreaOfOperationTaskEntity, options: GenerateSectionsOptions, allAcsCodes: AcsCodeStorage, acsTestPortionId: number) {
        const section: PlanOfActionSectionEntity = {
            _itemName: AppMetaDataItemNames.PlanOfActionSection,
            PlanOfActionId: planOfAction.PlanOfActionId,
            PlanOfActionSectionTypeId: this.getSectionTypeId(options),
            AcsAreaOfOperationId: aoo.AcsAreaOfOperationId,
            AcsAreaOfOperationTaskId: task?.AcsAreaOfOperationTaskId,
            PlanOfActionSectionQuestion: [],
            SectionOrder: planOfAction.PlanOfActionSection.length + 1,
            AcsTestPortionId: acsTestPortionId
        };

        planOfAction.PlanOfActionSection.push(section);

        // if (options.includeAllAcsCodes) {
        //     let ascCodes = allAcsCodes.AreaOfOperationCodes[aoo.AcsAreaOfOperationId]?.acsCodes || [];

        //     if (task) {
        //         ascCodes = allAcsCodes.TaskCodes[aoo.AcsAreaOfOperationId]?.acsCodes || [];
        //     }

        //     this.generateQuestions(section, ascCodes, options.useQuestionBank);
        // }
    }

    getSectionTypeId(options: GenerateSectionsOptions): number {
        if (options.buildTaskSections) {
            return PlanOfActionSectionTypeAllItems.Task;
        }
        return PlanOfActionSectionTypeAllItems.AreaOfOperation;

    }

    generateQuestions(section: PlanOfActionSectionEntity, questions: PlanOfActionSectionQuestionEntity[], options: GenerateCodesOptions) {
        if (!options.useQuestionBank) {
            for (let acsCode of options.acsCodes) {
                const question: PlanOfActionSectionQuestionEntity = {
                    _itemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
                    PlanOfActionSectionId: section.PlanOfActionSectionId,
                    AcsCodeId: acsCode.AcsCodeId
                }

                questions.push(question);
            }
            return;
        }

        for (let acsCodeQuestion of options.questions) {
            const question: PlanOfActionSectionQuestionEntity = {
                _itemName: AppMetaDataItemNames.PlanOfActionSectionQuestion,
                PlanOfActionSectionId: section.PlanOfActionSectionId,
                AcsCodeId: acsCodeQuestion.AcsCodeId,
                QuestionHtml: acsCodeQuestion.QuestionBankQuestionHtml
            }

            questions.push(question);
        }

    }

    addRequiredAcsCodes(applicantWritten: ApplicantAcsWrittenResultEntity, options: GenerateCodesOptions) {
        applicantWritten.ApplicantAcsWrittenResultMissedCode = applicantWritten.ApplicantAcsWrittenResultMissedCode || [];

        for (const acsCode of options?.acsCodes || []) {
            const existingCode = applicantWritten.ApplicantAcsWrittenResultMissedCode.find(porac => porac.AcsCodeId == acsCode.AcsCodeId);

            if (!existingCode) {
                const newCode = AppMetaData.ApplicantAcsWrittenResultMissedCode.CreateEntity();
                newCode.AcsCodeId = acsCode.AcsCodeId;
                //const newCode: PlanOfActionRequiredAcsCodeEntity = {
                //     _itemName: AppMetaDataItemNames.PlanOfActionRequiredAcsCode,
                //     PlanOfActionId: planOfAction.PlanOfActionId,
                //     AcsCodeId: acsCode.AcsCodeId
                // }

                applicantWritten.ApplicantAcsWrittenResultMissedCode.push(newCode);
            }
        }
    }

    addMissedCodesToSections(planOfAction: PlanOfActionEntity): Observable<{ codesAdded: number, missingCodeCount: number }> {
        const result: { codesAdded: number, missingCodeCount: number } = {
            codesAdded: 0,
            missingCodeCount: 0
        };

        return this.planOfActionRepositoryService.getAcsCodeStorage(planOfAction.AcsTestId)
            .pipe(map(acsCodeStorage => {
                const acsCodeStatuses = this.planOfActionRepositoryService.getAcsCodeStatus(planOfAction);

                for (const section of planOfAction?.PlanOfActionSection || []) {
                    const codesForSection = this.planOfActionRepositoryService.getAcsCodesForSection(acsCodeStorage, section);

                    if (!codesForSection) {
                        continue;
                    }

                    for (const acsCode of codesForSection.acsCodes) {
                        if (acsCodeStatuses.codesById[acsCode.AcsCodeId]?.missing) {
                            const question = AppMetaData.PlanOfActionSectionQuestion.CreateEntity();

                            question.AcsCodeId = acsCode.AcsCodeId;
                            question.PlanOfActionSectionId = section.PlanOfActionSectionId;

                            section.PlanOfActionSectionQuestion.push(question);

                            // Set to not missing
                            acsCodeStatuses.codesById[acsCode.AcsCodeId].missing = false;
                            result.codesAdded++;
                        }
                    }
                }

                for (const acsCodeId in acsCodeStatuses.codesById) {
                    if (acsCodeStatuses.codesById.hasOwnProperty(acsCodeId)) {
                        if (acsCodeStatuses.codesById[acsCodeId].missing) {
                            result.missingCodeCount++;
                        }
                    }
                }

                return result;
            }));
    }
}


