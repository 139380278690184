import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AcsCodesLookupQueryEntity, AppMetaData, PlanOfActionEntity, PlanOfActionQuestionResultEntity, PlanOfActionSectionEntity, PlanOfActionSectionQuestionEntity, PlanOfActionSectionTypeAllItems, PlanOfActionSectionTypeEntity } from '../../../../meta-data/app-meta-data.service';
import { booleanConverter, DwContainerOptions, DwInputConverter, isFirstChange } from '@devwareapps/devware-cap';
import { PlanOfActionRepositoryService } from '../../services/plan-of-action-repository.service';
import { AcsCodesStorageItem, AcsCodeStatuses } from '../../models/acs-code-items.model';
import { PlanOfActionUtilService } from '../../services/plan-of-action-util.service';

@Component({
  selector: 'app-plan-of-action-execute-section',
  templateUrl: './plan-of-action-execute-section.component.html',
  styleUrls: ['./plan-of-action-execute-section.component.scss']
})
export class PlanOfActionExecuteSectionComponent implements OnInit, OnChanges {

  @Input() planOfAction: PlanOfActionEntity;
  @Input() planOfActionSection: PlanOfActionSectionEntity;
  @Input() sectionTypes: PlanOfActionSectionTypeEntity[];

  @Input() planOfActionQuestionResults: PlanOfActionQuestionResultEntity[];
  @Input() acsCodeStatuses: AcsCodeStatuses;
  @Input() inPrintMode: boolean = false;

  @Output() resultChange = new EventEmitter<PlanOfActionQuestionResultEntity>();

  @DwInputConverter(booleanConverter) 
  @Input() useTitleOnlyContainer: boolean = false;

  sectionType: PlanOfActionSectionTypeEntity;
  showQuestion: boolean;
  sectionTitle: string;
  showAcsCode: boolean;

  
  acsCodes: AcsCodesStorageItem;
  collapsed: boolean = true;
  missingAcsCodeImageUrl: string;


  constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService, private planOfActionUtilService: PlanOfActionUtilService) {

    this.missingAcsCodeImageUrl = this.planOfActionUtilService.getMissedAcsCodeImageUrl();
   }
  
  containerOptions: DwContainerOptions;
  
  currentAcsCodes: AcsCodesLookupQueryEntity[];
  
  ngOnInit(): void {
    this.setupSection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isFirstChange(changes)) {
      this.setupSection();
    }

    if (!changes.planOfActionSection?.firstChange){
      this.setupSection(true);
    }
  }

  updateResult(result: PlanOfActionQuestionResultEntity) {
    this.resultChange.emit(result);
  }

  initComplete = false;

  private setupSection(force: boolean = false) {
    if (!this.planOfAction || !this.planOfActionSection || !this.sectionTypes || !this.planOfActionQuestionResults) {
      return;
    }

    if (this.initComplete && !force) {
      return;
    }

    this.setupContainer();

    this.setupQuestionResults();

    this.setupAcsCodes();

    this.initComplete = true;
  }

  resultMapping: { [prop: number]: PlanOfActionQuestionResultEntity } = {};

  setupQuestionResults() {
    for(const question of this.planOfActionSection.PlanOfActionSectionQuestion) {
      let result = this.planOfActionQuestionResults.find(x => x.PlanOfActionSectionQuestionId == question.PlanOfActionSectionQuestionId);

      if (!result) {
        result = AppMetaData.PlanOfActionQuestionResult.CreateEntity();
        result.PlanOfActionSectionQuestionId = question.PlanOfActionSectionQuestionId;
        
        this.planOfActionQuestionResults.push(result);
      }

      this.resultMapping[question.PlanOfActionSectionQuestionId] = result;
    }
  }

  private setupContainer() {
    if (!this.planOfAction || !this.planOfActionSection || !this.sectionTypes) {
      return;
    }

    this.sectionType = this.sectionTypes?.find(x => x.PlanOfActionSectionTypeId == this.planOfActionSection?.PlanOfActionSectionTypeId);

    console.log('setupContainer');
    if (!this.sectionType) {
      return;
    }

    this.showQuestion = this.planOfAction.OptionsShowQuestionForAcsCode;

    switch (this.planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.AreaOfOperation:

      //this.planOfActionSection.AcsAreaOfOperationIdDisplay
        this.sectionTitle = `${this.planOfActionSection.AcsAreaOfOperationIdDisplay}`;
        this.showAcsCode = true;
        break;
      case PlanOfActionSectionTypeAllItems.Task:
        this.sectionTitle = `${this.planOfActionSection.AcsAreaOfOperationIdDisplay} - ${this.planOfActionSection.AcsAreaOfOperationTaskIdDisplay}`;
        this.showAcsCode = true;
        break;
      default:
        this.sectionTitle = this.planOfActionSection.SectionName;
        this.showAcsCode = false;
        this.showQuestion = true;
    }

    this.containerOptions = {
      useContainerStyling: true,
      //styleName: 'TITLE_ONLY_LINE',
      // expandCollapseStyle: DwExpandCollapseStyle.chevron,
      // expandCollpaseLocation: DwExpandCollapseLocation.left,
      containerAllowCollapse: false,
      //containerCollapseDefault: true,
      
      containerHeaderIcon: this.sectionType.AcsTestPortion.IconClass,
      showTitle: true,
    }

    if(this.useTitleOnlyContainer) {
      this.containerOptions.styleName = 'TITLE_ONLY_LINE';
      this.containerOptions.containerHeaderForegroundColor = this.sectionType.AcsTestPortion.HeaderClass;
    } else {
      this.containerOptions.containerHeaderBackgroundColor= this.sectionType.AcsTestPortion.HeaderClass;
    }

  }


  private setupAcsCodes() {
    if (!this.planOfAction || !this.planOfActionSection) {
      return;
    }

    // if (this.acsCodes) {
    //   return;
    // }

    if (!this.planOfActionSection.AcsAreaOfOperationId) {
      return;
    }

    //console.log(`getting acs codes - start  ${QuestionEditControlComponent.count}`);
    this.planOfActionRepositoryService.getAcsCodes(this.planOfAction.AcsTestId, this.planOfActionSection.AcsAreaOfOperationId, this.planOfActionSection.AcsAreaOfOperationTaskId)
      .subscribe(acsCodes => {
        this.acsCodes = acsCodes;

      })
  }

}
