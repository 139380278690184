import { Inject, Injectable } from "@angular/core";
import { DwDataItemKeyValues, DwDataRequest, DwDatasource, DwItemType, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataHierarchy, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwQueryPrefetchPath, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { Observable } from "rxjs";

@DwDatasource({
    keyName: 'PlanOfActionDataSource',
    display: 'Plan of Action Data Source',
    itemName: AppMetaDataItemNames.PlanOfAction,
})
@Injectable()
export class PlanOfActionDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.PlanOfAction);
    }

    initDataSource() {
        this.setupOrmDataHierarchyFromQuery(this.item, this.ormDataHierarchy);
    }

    private setupOrmDataHierarchyFromQuery(item : DwItemType | DwQuery, ormDataHierarchy? : DwOrmDataHierarchy[]) {
        const sectionHierarchy : DwOrmDataHierarchy = {
            itemName: AppMetaData.PlanOfActionSection.ItemDetail.itemName,
            propertyName: AppMetaData.PlanOfAction.Relations.PlanOfActionSection.propertyName,
            children: [{
                itemName: AppMetaData.PlanOfActionSectionQuestion.ItemDetail.itemName,
                propertyName: AppMetaData.PlanOfActionSection.Relations.PlanOfActionSectionQuestion.propertyName,
            }]
        };

        const acsWrittenHierarchy : DwOrmDataHierarchy = {
            itemName: AppMetaData.ApplicantAcsWrittenResult.ItemDetail.itemName,
            propertyName: AppMetaData.PlanOfAction.Relations.ApplicantAcsWrittenResult.propertyName,
            children: [{
                itemName: AppMetaData.ApplicantAcsWrittenResultMissedCode.ItemDetail.itemName,
                propertyName: AppMetaData.ApplicantAcsWrittenResult.Relations.ApplicantAcsWrittenResultMissedCode.propertyName,
            }]
        };
      
        ormDataHierarchy.push(sectionHierarchy);
        ormDataHierarchy.push(acsWrittenHierarchy);
    }
    
    loadItemFromQuery(query: DwQuery, keyValues: DwDataItemKeyValues | number): Observable<any> {
        const updatedQuery = this.buildFinalQuery(query);

        return super.loadItemFromQuery(updatedQuery, keyValues);
    }
    
    saveItem(entity: any, saveOptions?: any, suppressErrorMessage: boolean = true, useRootItemQuery: boolean = true): Observable<any> {
        console.log('Save item', entity);
        return super.saveItem(entity, saveOptions, suppressErrorMessage, useRootItemQuery);
    }
    

    private buildFinalQuery(query: DwQuery): DwQuery {
        const planOfActionQuery = AppMetaData.PlanOfAction.CreateQueryBuilder(query);

        const acsWrittenResultQuery = AppMetaData.ApplicantAcsWrittenResult.CreateQueryBuilder();

        acsWrittenResultQuery.addPrefetch(r => r.ApplicantAcsWrittenResultMissedCode);

        planOfActionQuery.addPrefetch(r => r.ApplicantAcsWrittenResult, acsWrittenResultQuery.query);

        let prefetchSection = query.Prefetch.find(p => p.RelationName === AppMetaData.PlanOfAction.Relations.PlanOfActionSection.relationName);

        if (prefetchSection == null) {
             planOfActionQuery.addPrefetch(r => r.PlanOfActionSection);

             prefetchSection = query.Prefetch.find(p => p.RelationName === AppMetaData.PlanOfAction.Relations.PlanOfActionSection.relationName);
        }

        const sectionQuery = AppMetaData.PlanOfActionSection.CreateQueryBuilder(prefetchSection?.Query);

        sectionQuery.addOrderBy(a => a.SectionOrder);

        prefetchSection.Query = sectionQuery.query;

        let prefetchSectionQuestion = sectionQuery.query.Prefetch?.find(p => p.RelationName === AppMetaData.PlanOfActionSection.Relations.PlanOfActionSectionQuestion.relationName);

        if (!prefetchSectionQuestion) {
            sectionQuery.addPrefetch(r => r.PlanOfActionSectionQuestion);

            prefetchSectionQuestion = sectionQuery.query.Prefetch?.find(p => p.RelationName === AppMetaData.PlanOfActionSection.Relations.PlanOfActionSectionQuestion.relationName);
        }

        const questionQuery = AppMetaData.PlanOfActionSectionQuestion.CreateQueryBuilder(prefetchSectionQuestion?.Query);

        questionQuery.addOrderBy(a => a.QuestionOrder);

        prefetchSectionQuestion.Query = questionQuery.query;

        return planOfActionQuery.query;
    }



}