import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestStatusAllItems, CheckRideRequestSubStatusAllItems, PlanOfActionDetailsQueryEntity, PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";

@DwAction({
    keyName: 'poa-complete-checkride-action',
    display: 'Complete Plan of Action Checkride',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        useParentDisplay: false,
        buttonText: 'Complete Checkride',
        buttonClass: 'btn btn-success mr-1 mt-1',
        iconClass: 'fa fa-save'
    },
    configData: {
        requireValidation: true,
        redirectAfter: true,
        actionMessage: 'Saving...',
    }
})
@Injectable()
export class POACompleteCheckrideActionService implements DwActionHandler {

    constructor(private dwRoutingService: DwRoutingService) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

       const variables : { RemoveAllQuestions: boolean } = actionState.formApi.getVariableContextValue();
       const planOfAction = actionState.formApi.getFormData<PlanOfActionEntity>();

       if (planOfAction.CheckRideRequest?.CheckRideRequestId) {
            planOfAction.CheckRideRequest.CheckRideResult.CheckRideDateTime = planOfAction.PlanOfActionResult.CheckRideStartDateTime;
       } else {
        // Don't save the checkride request if it doesn't exist
        planOfAction.CheckRideRequest._isDeleted = true;
       }

       if (variables.RemoveAllQuestions) {
           this.removeAllQuestions(planOfAction);
        }

        actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction);
        actionState.formApi.formGroup.patchValue(planOfAction);
        actionState.formApi.setFormData(planOfAction);
        
        return actionState.formApi.saveData(true);
    }

    removeAllQuestions(planOfAction: PlanOfActionEntity): void {
        for(let section of planOfAction.PlanOfActionSection) {
            section._isDeleted = true;

            for(let question of section.PlanOfActionSectionQuestion) {
                question._isDeleted = true;
            }
        }
    }

    redirectToExecute(planOfAction: PlanOfActionEntity): void {
        const url = `/plan-of-action/plans/edit/${planOfAction.PlanOfActionId}/execute`;

        this.dwRoutingService.navigateToUrl(url, null);
    }

}