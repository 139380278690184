import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionSectionTypeAllItems } from '../../../../meta-data/app-meta-data.service';
import { PlanOfActionRepositoryService } from '../../services/plan-of-action-repository.service';
import { AcsReferenceInfo } from '../../models/acs-reference-info.model';

@Component({
  selector: 'app-plan-of-action-section-edit',
  templateUrl: './plan-of-action-section-edit.component.html',
  styleUrls: ['./plan-of-action-section-edit.component.scss']
})
export class PlanOfActionSectionEditComponent implements OnInit, OnChanges {

  @Input() planOfAction: PlanOfActionEntity;
  @Input() planOfActionSection: PlanOfActionSectionEntity;
  @Input() acsReferenceInfo: AcsReferenceInfo;
  @Output() planOfActionSectionChange = new EventEmitter<PlanOfActionSectionEntity>();
  sectionTitle: string;

  constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService) { }

  showEdit = false;

  ngOnInit(): void {
    this.setupSection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupSection();
  }

  sectionTitleChange() {
    this.planOfActionSectionChange.emit(this.planOfActionSection);
  }

  private setupSection() {
    if (!this.planOfAction || !this.planOfActionSection) {
      return;
    }

    switch (this.planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.Manual:
        this.showEdit = true;
        break;
      default:

        this.showEdit = false;
        this.sectionTitle = this.planOfActionRepositoryService.getSectionTitle(this.planOfActionSection, this.acsReferenceInfo);
        break;
    }
    if (this.planOfActionSection.PlanOfActionSectionTypeId == PlanOfActionSectionTypeAllItems.Manual) {
      this.showEdit = true;
      return;
    }

  }
}
