import { Injectable } from "@angular/core";
import { DwAction, DwActionHandler, DwActionResult, DwActionType, DwMdFormActionState, DwMdFormContextInfo, DwModalButtonStandardConfigs, DwModalConfig, DwModalService, DwModalSize, DwRoutingService } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { ApplicantAcsWrittenResultEntity, AppMetaDataItemNames, CheckRideRequestEntity, CheckRideRequestStatusAllItems, CheckRideRequestSubStatusAllItems, PlanOfActionDetailsQueryEntity, PlanOfActionEntity, PlanOfActionSectionEntity, PlanOfActionStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { PlanOfActionRepositoryService } from "../services/plan-of-action-repository.service";
import { GenerateSectionsModalComponent } from "../components/generate-sections-modal/generate-sections-modal.component";
import { map, tap } from "rxjs/operators";
import { PlanOfActionGeneratorService } from "../services/plan-of-action-generator.service";
import { GenerateQuestionsModalComponent } from "../components/generate-questions-modal/generate-questions-modal.component";
import { AcsCodeSelectionModalOptions, AcsCodeSelectionModalResult } from "../models/acs-code-selection-modal-options.model";
import { AcsCodeStatuses } from "../models/acs-code-items.model";

@DwAction({
    keyName: 'checkride-save-and-goto -poa',
    display: 'Save Checkride and Goto Plan Of Action',
    actionType: DwActionType.formAction,
    isGlobal: false,
    parentItemName: [AppMetaDataItemNames.PlanOfAction],
    allowOnce: true,
    defaultButtonConfig: {
        useButtonDefaults: false,
        useParentDisplay: false,
        buttonText: 'Go to Plan Of Action',
        buttonClass: 'btn btn-warning mr-1 mt-1',
        iconClass: 'fa fa-map'
    },
    configData: {
        requireValidation: true,
        redirectAfter: false,
        actionMessage: 'Saving...',
    }
})
@Injectable()
export class CheckrideSaveAndGotoPOAActionService implements DwActionHandler {

    constructor(private dwRoutingService: DwRoutingService) { }

    handleAction(actionState: DwMdFormActionState): Observable<DwActionResult> {

        return actionState.formApi.saveData(true)
            .pipe(map((result) => {
                const checkrideRequest: CheckRideRequestEntity = result.saveResult;

                const url = `/plan-of-action/plans/edit/${checkrideRequest.PlanOfAction.PlanOfActionId}`;

                this.dwRoutingService.navigateToUrl(url, null);

                return result;
            }));
    }

}