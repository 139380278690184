import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwContainerOptions, DwExpressionService, DwFormContextMode, DwFormPrintConfig, DwLookupInfo, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken } from '@devwareapps/devware-cap';
import { AcsCodesLookupQueryEntity, AcsTestPortionEntity, AppMetaDataItemNames, AppMetaDataLookups, PlanOfActionEntity, PlanOfActionQuestionResultEntity, PlanOfActionSectionEntity, PlanOfActionSectionTypeAllItems, PlanOfActionSectionTypeEntity, PlanOfActionStatusAllItems } from '../../../../meta-data/app-meta-data.service';
import { AcsCodesStorageItem, AcsCodeStatuses } from '../../models/acs-code-items.model';
import { GenerateQuestionsActionService } from '../../actions/generate-questions-action.service';
import { PlanOfActionGeneratorService } from '../../services/plan-of-action-generator.service';
import { PlanOfActionRepositoryService } from '../../services/plan-of-action-repository.service';
import { PlanOfActionExecuteVariables } from '../../models/plan-of-action-execute-variables.model';
import { AcsTestPortionResult, PlanOfActionUtilService } from '../../services/plan-of-action-util.service';

@DwComponent({
  key: 'plan-of-action-execute',
  name: 'Plan of Action Execute',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.PlanOfAction
})
@Component({
  selector: 'app-plan-of-action-execute',
  templateUrl: './plan-of-action-execute.component.html',
  styleUrls: ['./plan-of-action-execute.component.scss']
})
export class PlanOfActionExecuteComponent extends DwSectionBaseComponent {
  static expandedSections: { [prop: number]: boolean } = {};

  static printCurrentAcsTestPortions: number[];

  formApi: DwMetaDataFormApi;
  planOfAction: PlanOfActionEntity;
  planOfActionSections: PlanOfActionSectionEntity[];

  rootFormGroup: any;
  //sectionTypes: PlanOfActionSectionTypeEntity[];
  sectionIndex: number;
  planOfActionQuestionResults: PlanOfActionQuestionResultEntity[];
  currentSection: PlanOfActionSectionEntity;
  sectionTypes: PlanOfActionSectionTypeEntity[];
  hasPrevious: boolean;
  hasNext: boolean;
  selectedSectionId: number;

  inPrintMode: boolean = false;
  currentTestSections: PlanOfActionSectionEntity[] = [];

  sectionList: DwLookupInfo[] = [];
  acsTestPortions: AcsTestPortionEntity[];

  currentAcsTestPortion: AcsTestPortionResult;
  acsCodeStatuses: AcsCodeStatuses;

  showAllSections: boolean = true;
  showAllSectionsToggle: boolean = true;
  acsTestDetails: AcsTestPortionResult[];
  acsTestPortionContainer: DwContainerOptions;

  acsTestSelectionContainer = {
    useContainerStyling: true,
    //styleName: 'TITLE_ONLY_LINE',
    // expandCollapseStyle: DwExpandCollapseStyle.chevron,
    // expandCollpaseLocation: DwExpandCollapseLocation.left,
    containerAllowCollapse: false,
    //containerCollapseDefault: true,
    // containerHeaderBackgroundColor: this.currentAcsTestPortion.acsTestPortion.HeaderClass,
    // containerHeaderIcon: this.currentAcsTestPortion.acsTestPortion.IconClass,
    showTitle: false,
  }
  exporting: boolean;
  exportingTestPortionId: number;
  printSections: AcsTestPortionResult[];
  printDetails: any;
  printTestDetails: AcsTestPortionResult[];

  constructor(
    dwExpressionService: DwExpressionService,
    dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwMetaDataFormStateService: DwMetaDataFormStateService,
    private planOfActionRepositoryService: PlanOfActionRepositoryService,
    private generateQuestionsActionService: GenerateQuestionsActionService,
    private planofActionGeneratorService: PlanOfActionGeneratorService,
    private planOfActionUtilService: PlanOfActionUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);

    this.formApi = this.dwMetaDataFormStateService.state.formApi;
  }


  resultsToSave: PlanOfActionQuestionResultEntity[] = [];

  resultsToSaveMap: { [prop: number]: PlanOfActionQuestionResultEntity } = {};

  goToAcsTestPortion(acsPortion: AcsTestPortionResult) {
    this.currentAcsTestPortion = acsPortion;

    this.updateVariables();

    this.setupAcsTestPortion();
  }

  private updateVariables() {
    this.formApi.patchVariableContext<PlanOfActionExecuteVariables>({ ExecutingTest: !!this.currentAcsTestPortion })
  }

  print(acsTestPortion: AcsTestPortionResult) {
    PlanOfActionExecuteComponent.printCurrentAcsTestPortions = [acsTestPortion.acsTestPortion.AcsTestPortionId];

    const formPrintConfig: DwFormPrintConfig = {
      actionMessage: `Exporting ${acsTestPortion.acsTestPortion.AcsTestPortion} to PDF...`,
      exportPDFFileName: `PlanOfAction ${acsTestPortion.acsTestPortion.AcsTestPortion}.pdf`,
      redirectAfter: false,
      renderMultiplePage: true,
      //printConfigKeyOverride: 'dep-admin-execute-print',
    }
    this.exportingTestPortionId = acsTestPortion.acsTestPortion.AcsTestPortionId;
    this.exporting = true;

    this.formApi.print(formPrintConfig).subscribe(() => {
      this.exporting = false;
    });

  }

  setupAcsTestPortion() {
    this.currentTestSections = this.currentAcsTestPortion.sections;

    this.sectionIndex = 0;

    this.setupSectionList();

    this.setupCurrentSection();

    this.acsTestPortionContainer = {
      useContainerStyling: true,
      //styleName: 'TITLE_ONLY_LINE',
      // expandCollapseStyle: DwExpandCollapseStyle.chevron,
      // expandCollpaseLocation: DwExpandCollapseLocation.left,
      containerAllowCollapse: false,
      //containerCollapseDefault: true,
      containerHeaderBackgroundColor: this.currentAcsTestPortion.acsTestPortion.HeaderClass,
      containerHeaderIcon: this.currentAcsTestPortion.acsTestPortion.IconClass,
      showTitle: true,
    }

  }

  ngOnInit(): void {
    this.loadReferenceData();
    this.setupDataWatch();
  }

  gotoAcsPortion(acsPortion: number) {

  }

  resultChange(result: PlanOfActionQuestionResultEntity) {
    if (!result.PlanOfActionResultId) {
      result.PlanOfActionResultId = this.planOfAction.PlanOfActionResultId;
    }
    this.resultsToSaveMap[result.PlanOfActionSectionQuestionId] = result;

    const resultArray: PlanOfActionQuestionResultEntity[] = [];

    for (const key in this.resultsToSaveMap) {
      if (this.resultsToSaveMap.hasOwnProperty(key)) {
        resultArray.push(this.resultsToSaveMap[key]);
      }
    }

    this.resultsToSave = resultArray;
  }

  goToSection() {
    if (!this.selectedSectionId) {
      return;
    }

    this.sectionIndex = this.currentTestSections.findIndex(x => x.PlanOfActionSectionId == this.selectedSectionId);

    this.setupSection();
  }

  saveCurrent() {
    if (this.resultsToSave.length == 0) {
      return;
    }

    this.planOfActionRepositoryService.savePlanOfActionQuestionResults(this.resultsToSave)
      .subscribe(results => {
        this.resultsToSave = [];
        this.resultsToSaveMap = {};

        for (var result of results) {
          const existingResult = this.planOfActionQuestionResults.find(x => x.PlanOfActionSectionQuestionId == result.PlanOfActionSectionQuestionId);

          if (existingResult) {
            const index = this.planOfActionQuestionResults.indexOf(existingResult);

            this.planOfActionQuestionResults[index] = result;
          } else {
            this.planOfActionQuestionResults.push(result);
          }
        }

        //this.currentAcsTestPortion = null;
        this.updateVariables();

        this.buildAcsTestDetails();
      });
  }


  loadReferenceData() {
    this.planOfActionRepositoryService.getAcsTestPortions()
      .subscribe(acsTestPortions => {
        this.acsTestPortions = acsTestPortions;

        this.buildAcsTestDetails();
      });

    this.planOfActionRepositoryService.getPlanOfActionSectionTypes()
      .subscribe(sectionTypes => {
        this.sectionTypes = sectionTypes;
        this.buildAcsTestDetails();
      });
  }

  setupDataWatch() {
    this.formApi.getFormDataChanges(100)
      .subscribe(dataChange => {
        if (this.formApi.isDataLoadComplete) {
          this.checkForDataChange();
        }
      });

    if (this.formApi.isDataLoadComplete) {
      this.checkForDataChange();
    }
  }


  buildAcsTestDetails() {
    if (!this.planOfAction || !this.acsTestPortions || !this.planOfActionSections || !this.planOfActionQuestionResults) {
      return;
    }

    this.acsTestDetails = this.planOfActionUtilService.buildAcsTestDetails(this.planOfAction, this.planOfActionSections, this.planOfActionQuestionResults, this.acsTestPortions);

    if (this.acsTestDetails.length ==0) {
      this.formApi.setFormMessage({ messageBody: 'No Sections have been created.  Return to edit and add sections & qestions.', severity: DwMessageSeverity.error });
    }
  }

  initComplete = false;

  private checkForDataChange() {
    this.planOfAction = this.formApi.getFormData<PlanOfActionEntity>();

    this.acsCodeStatuses = this.planOfActionRepositoryService.getAcsCodeStatus(this.planOfAction);

    if (this.initComplete) {
      return;
    }

    this.initComplete = true;

    if (!this.planOfAction.PlanOfActionResultId) {
      this.planOfActionRepositoryService.createPlanOfActionResult(this.planOfAction)
        .subscribe(planOfAction => {
          this.planOfAction = planOfAction;

          this.formApi.setFormData(planOfAction);

          this.loadPlanOfActionQuestionResults();
        });
    } else {
      this.loadPlanOfActionQuestionResults();
    }

    this.loadPlanOfActionSections();
  }

  private loadPlanOfActionSections() {
    this.planOfActionRepositoryService.loadPlanOfActionSections(this.planOfAction.PlanOfActionId)
      .subscribe(planOfActionSections => {
        this.planOfActionSections = planOfActionSections;

        this.buildAcsTestDetails();

        this.setupPrint();
      });
  }

  private setupPrint() {

    if (this.formApi.formMode == DwFormContextMode.print) {
      this.inPrintMode = true;

      const printPortions = PlanOfActionExecuteComponent.printCurrentAcsTestPortions;

      const printTestDetails = this.acsTestDetails.filter(x => printPortions == null || printPortions.find(acsTestPortionId => acsTestPortionId == x.acsTestPortion.AcsTestPortionId));

      this.printTestDetails = printTestDetails;

      // this.printSections = this.acsTestDetails.filter(x => printPortions == null || printPortions.find(acsTestPortionId => acsTestPortionId == x.acsTestPortion.AcsTestPortionId));



      // this.goToAcsTestPortion(this.printSections[0]);

    }



  }


  private setupSectionList() {
    const sections = this.currentTestSections.map(x => {
      return {
        Id: x.PlanOfActionSectionId.toString(),
        Display: this.getSectionDisplay(x)
      };
    })

    this.sectionList = [{ Id: '0', Display: ' ' }, ...sections];
  }

  private getSectionDisplay(planOfActionSection: PlanOfActionSectionEntity): any {
    switch (planOfActionSection.PlanOfActionSectionTypeId) {
      case PlanOfActionSectionTypeAllItems.AreaOfOperation:
        return `${planOfActionSection.AcsAreaOfOperationIdDisplay}`;

      case PlanOfActionSectionTypeAllItems.Task:
        return `${planOfActionSection.AcsAreaOfOperationIdDisplay} - ${planOfActionSection.AcsAreaOfOperationTaskIdDisplay}`;

      default:
        return planOfActionSection.SectionName;
    }
  }

  private loadPlanOfActionQuestionResults() {
    if (!this.planOfAction.PlanOfActionResultId) {
      return;
    }

    this.planOfActionRepositoryService.loadPlanOfActionQuestionResults(this.planOfAction.PlanOfActionResultId)
      .subscribe(planOfActionQuestionResults => {
        this.planOfActionQuestionResults = planOfActionQuestionResults;
        this.buildAcsTestDetails();
      });
  }

  setupCurrentSection() {
    if (!this.currentTestSections) {
      return;
    }

    this.sectionIndex = 0;

    this.setupSection();

    this.currentSection = this.currentTestSections[this.sectionIndex];
  }

  nextSection() {
    this.sectionIndex++;

    if (this.sectionIndex > this.currentTestSections.length) {
      this.sectionIndex = this.currentTestSections.length;
    }

    this.setupSection();
  }

  previousSection() {
    this.sectionIndex--;

    if (this.sectionIndex < 0) {
      this.sectionIndex = 0;
    }

    this.setupSection();
  }

  complete() {

    // this.formApi.patchFormData({ PlanOfActionStatusId: PlanOfActionStatusAllItems.Complete})

    this.stop();
  }

  stop() {
    this.saveCurrent();

    this.currentAcsTestPortion = null;
    this.updateVariables();

    this.formApi.patchVariableContext<PlanOfActionExecuteVariables>({ ShowPlan: false });
  }

  setupSection() {
    // Always save first
    this.saveCurrent();

    this.currentSection = this.currentTestSections[this.sectionIndex];

    this.hasPrevious = this.sectionIndex > 0;
    this.hasNext = this.sectionIndex < this.currentTestSections.length - 1;
  }

}
