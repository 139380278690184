  <dw-container [options]="containerOptions" [(collapsed)]="collapsed" (collapsedChange)="collapsedChanged($event)" *ngIf="containerOptions">
    <span header>
        <span *ngIf="!edittingTitle">{{sectionTitle}}</span>
         <!-- <i class="fa fa-edit" (click)="editSectionTitle()" *ngIf="allowTitleEdit"></i></span> 
        <input type="text" [(ngModel)]="sectionTitle" *ngIf="edittingTitle" (blur)="onSectionTitleChange($event)" /> -->
    </span>
    <div body>
        <ng-container>
            <dw-button-bar>
                <button class="btn btn-primary" (click)="addQuestion()">Add Question</button>
                <button class="btn btn-warning" (click)="addAcsCodes()" *ngIf="showAcsCode">Add ACS Codes</button>

                <div right class="pull-right" style="text-align:right;margin-top:5px;" *ngIf="showAcsCode">
                    <i class="fa fa-times dw-red" title="Missed ACS Code"></i> Missed ACS Codes
                </div>
            </dw-button-bar>
            <div *ngIf="currentQuestions?.length == 0">
                No items have been added to this section.
            </div>

            <dw-ordered-list [(items)]="currentQuestions" (itemsChange)="onQuestionsReorder($event)" (itemRemoved)="onQuestionRemoved($event)"  *ngIf="currentQuestions?.length > 0">
                <ng-container *dwListItem="let question">
                    <app-plan-of-action-question [question]="question" (questionChange)="onQuestionChange($event)" [currentAcsCodes]="currentAcsCodes" [acsCodes]="acsCodes" [acsCodeStatuses]="acsCodeStatuses"
                    [showQuestion]="showQuestion" [showAcsCode]="showAcsCode"></app-plan-of-action-question>
                </ng-container>
            </dw-ordered-list>
        </ng-container>
    </div>
</dw-container>
<!-- 
{{collapsed}}

<button (click)="collapsed = !collapsed">Toggle</button> -->

<!-- <pre>{{planOfActionSections | json}}</pre> -->


