import { Inject, Injectable } from "@angular/core";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwConfigServiceToken, DwConfigService, DwSecurityUserService, DwSecurityTopics, DwCacheService, flattenArray } from "@devwareapps/devware-cap";
import { combineLatest, Observable } from "rxjs";
import { PilotExaminerEntity, AppMetaData, AcsCodesLookupQueryEntity, PlanOfActionEntity, AcsTestEntity, PlanOfActionSectionTypeEntity, PlanOfActionSectionEntity, PlanOfActionQuestionResultEntity, PlanOfActionResultEntity, PlanOfActionSectionTypeAllItems, CheckRideRequestEntity, AcsTestPortionEntity, PlanOfActionSectionQuestionEntity } from "../../../meta-data/app-meta-data.service";
import { map, mergeMap } from "rxjs/operators";

import { GenerateSectionsOptions } from "../models/generate-sections-options.model";
import { AcsCodeGroup, AcsCodeStatuses, AcsCodeStorage, AcsCodesStorageItem } from "../models/acs-code-items.model";
import { AcsReferenceInfo } from "../models/acs-reference-info.model";

@Injectable({ providedIn: 'root' })
export class PlanOfActionRepositoryService {
    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService
    ) {
    }

    getPlanOfActionSectionTypes(): Observable<PlanOfActionSectionTypeEntity[]> {
        const cacheInvalidationTopics = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.PlanOfActionSectionType.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsTestPortion.ItemDetail.itemName));

        return this.dwCacheService.get('planOfActionSectionTypes', this.loadPlanOfActionSectionTypes(), null, cacheInvalidationTopics);
    }

    getAcsTestPortions(): Observable<AcsTestPortionEntity[]> {
        const cacheInvalidationTopics = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsTestPortion.ItemDetail.itemName));

        return this.dwCacheService.get('acsTestPortions', this.loadAcsTestPortions(), null);
    }

    private loadAcsTestPortions(): Observable<AcsTestPortionEntity[]> {
        const query = AppMetaData.AcsTestPortion.CreateQueryBuilder();

        query.addOrderBy(o => o.AcsTestPortionOrder);

        return this.dwOrmDataService.executeQuery(query);
    }

    loadPlanOfActionSectionTypes(): Observable<PlanOfActionSectionTypeEntity[]> {
        const query = AppMetaData.PlanOfActionSectionType.CreateQueryBuilder();

        query.addPrefetch(r => r.AcsTestPortion);

        return this.dwOrmDataService.executeQuery(query);
    }

    loadPlanOfActionSections(planOfActionId: number): Observable<PlanOfActionSectionEntity[]> {
        const query = AppMetaData.PlanOfActionSection.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        query.addFilterAnd(f => f.Equal(a => a.PlanOfActionId, planOfActionId));

        query.addOrderBy(o => o.SectionOrder);
        var sectionQuestionQuery = AppMetaData.PlanOfActionSectionQuestion.CreateQueryBuilder();

        sectionQuestionQuery.addOrderBy(o => o.QuestionOrder);

        query.addPrefetch(r => r.PlanOfActionSectionQuestion, sectionQuestionQuery.query);

        return this.dwOrmDataService.executeQuery(query);
    }

    createPlanOfActionResult(planOfAction: PlanOfActionEntity): Observable<PlanOfActionEntity> {
        planOfAction.PlanOfActionResult = AppMetaData.PlanOfActionResult.CreateEntity();

        planOfAction.PlanOfActionResult.CreatedBy = 1;

        return this.dwOrmDataService.saveEntity(planOfAction, true);
    }

    savePlanOfActionQuestionResults(resultsToSave: PlanOfActionQuestionResultEntity[]) {
        return this.dwOrmDataService.saveEntityCollection(resultsToSave, false, false);
    }

    loadPlanOfActionQuestionResults(planOfActionResultId: number): Observable<PlanOfActionQuestionResultEntity[]> {
        const query = AppMetaData.PlanOfActionQuestionResult.CreateQueryBuilder();

        query.addFilterAnd(f => f.Equal(a => a.PlanOfActionResultId, planOfActionResultId));

        //query.addPrefetch(r => r.PlanOfActionSectionQuestion);

        return this.dwOrmDataService.executeQuery(query);
    }

    loadCheckrideAndAcsTestResult(checkrideRequestId: number): Observable<CheckRideRequestEntity> {
        const query = AppMetaData.CheckRideRequest.CreateQueryBuilder();

        query.addFields(f => [f.CheckRideRequestId, f.ApplicantAcsWrittenResultId]);

        query.addFilterAnd(f => f.Equal(a => a.CheckRideRequestId, checkrideRequestId));

        const writtenResultQuery = AppMetaData.ApplicantAcsWrittenResult.CreateQueryBuilder();

        writtenResultQuery.addPrefetch(r => r.ApplicantAcsWrittenResultMissedCode);

        query.addPrefetch(r => r.ApplicantAcsWrittenResult, writtenResultQuery.query);

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getAcsTest(acsTestId: number): Observable<AcsTestEntity> {
        const query = AppMetaData.AcsTest.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = true;

        const aooQuery = AppMetaData.AcsAreaOfOperation.CreateQueryBuilder();
        const taskQuery = AppMetaData.AcsAreaOfOperationTask.CreateQueryBuilder();

        query.addPrefetch(r => r.AcsAreaOfOperation, aooQuery.query);

        aooQuery.addPrefetch(r => r.AcsAreaOfOperationTask, taskQuery.query);

        taskQuery.addPrefetch(r => r.AcsCode);

        query.addFilterAnd(filters => filters.Equal(field => field.AcsTestId, acsTestId));

        return this.dwOrmDataService.executeQuerySingle(query);
    }

    getAcsReference(acsTestId: number): Observable<AcsReferenceInfo> {
        const cacheInvalidationTopics = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsCode.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsAreaOfOperationTask.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsAreaOfOperation.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsTest.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsCode.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.PlanOfActionSectionType.ItemDetail.itemName));

        const key = `acsReference_${acsTestId}`;

        return this.dwCacheService.get(key, this.loadAcsReference(acsTestId), null, cacheInvalidationTopics);
    }

    loadAcsReference(acsTestId: number): Observable<AcsReferenceInfo> {
        const obs: Observable<any>[] = [];

        obs.push(this.getAcsTest(acsTestId));
        obs.push(this.loadAcsCodes(acsTestId)); // Use load method since this will already be cached
        obs.push(this.getPlanOfActionSectionTypes());

        return combineLatest(obs)
            .pipe(map(([acsTest, acsCodes, sectionTypes]) => {

                return this.setupAcsReferenceInfo(acsTest, acsCodes, sectionTypes);
            }));
    }

    private setupAcsReferenceInfo(acsTest: AcsTestEntity, acsCodes: AcsCodeStorage, sectionTypes: PlanOfActionSectionTypeEntity[]): AcsReferenceInfo {
        const refInfo: AcsReferenceInfo = {
            acsTest: acsTest,
            sectionTypes: sectionTypes,
            acsCodes: acsCodes,
            areaOfOperationDisplays: {},
            taskDisplays: {}
        };

        // Build out AOO and Task displays
        for (let aoo of acsTest.AcsAreaOfOperation) {
            refInfo.areaOfOperationDisplays[aoo.AcsAreaOfOperationId] = aoo.AreaOfOperationName;

            for (let task of aoo.AcsAreaOfOperationTask) {
                refInfo.taskDisplays[task.AcsAreaOfOperationTaskId] = `${aoo.AreaOfOperationName} - ${task.TaskName}`
            }
        }

        return refInfo;
    }

    getSectionTitle(planOfActionSection: PlanOfActionSectionEntity, acsReferenceInfo: AcsReferenceInfo): string {

        switch (planOfActionSection.PlanOfActionSectionTypeId) {
            case PlanOfActionSectionTypeAllItems.AreaOfOperation:
                return acsReferenceInfo.areaOfOperationDisplays[planOfActionSection.AcsAreaOfOperationId];

            // const aos = acsTest.AcsAreaOfOperation.find(a => a.AcsAreaOfOperationId === planOfActionSection.AcsAreaOfOperationId);

            // return `${aos?.AreaOfOperationName}`;

            case PlanOfActionSectionTypeAllItems.Task:
                return acsReferenceInfo.taskDisplays[planOfActionSection.AcsAreaOfOperationTaskId];

                // const aos2 = acsTest.AcsAreaOfOperation.find(a => a.AcsAreaOfOperationId === planOfActionSection.AcsAreaOfOperationId);
                // const task = aos2?.AcsAreaOfOperationTask.find(t => t.AcsAreaOfOperationTaskId === planOfActionSection.AcsAreaOfOperationTaskId);

                // return `${aos2?.AreaOfOperationName} - ${task.TaskName}`;
                break;

            default:
                return planOfActionSection?.SectionName;
        }
    }

    //getAcsCodeStatus(ApplicantAcsWrittenResult: ApplicantAcsWrittenResultEntity): AcsCodeStatuses {
    getAcsCodeStatus(planOfAction: PlanOfActionEntity): AcsCodeStatuses {
        const missedAcsCodes = planOfAction.ApplicantAcsWrittenResult?.ApplicantAcsWrittenResultMissedCode?.map(a => a.AcsCodeId) || [];

        const acsCodeStatuses: AcsCodeStatuses = {
            codesById: {}
        };

        for (const acsCode of missedAcsCodes) {
            acsCodeStatuses.codesById[acsCode] = {
                ascCodeId: acsCode,
                missing: true
            }
        }

        for (const section of planOfAction?.PlanOfActionSection || []) {
            for (const question of section.PlanOfActionSectionQuestion || []) {

                if (question._isDeleted) {
                    continue;
                }

                const acsCodeStatus = acsCodeStatuses.codesById[question.AcsCodeId];

                if (acsCodeStatus) {
                    acsCodeStatus.missing = false;
                }
            }
        }

        return acsCodeStatuses;
    }

    // addMissedCodesToSections(planOfAction: PlanOfActionEntity): Observable<AcsCodeStatuses> {
    //     return this.getAcsCodeStorage(planOfAction.AcsTestId)
    //         .pipe(map(acsCodeStorage => {
    //             const acsCodeStatuses = this.getAcsCodeStatus(planOfAction);

    //             for (const section of planOfAction?.PlanOfActionSection || []) {
    //                 const codesForSection = this.getAcsCodesForSection(acsCodeStorage, section);


    //                 for (const acsCode of codesForSection.acsCodes) {
    //                     if (acsCodeStatuses.codesById[acsCode.AcsCodeId]?.missing) {
    //                         const question = AppMetaData.PlanOfActionSectionQuestion.CreateEntity();

    //                         question.AcsCodeId = acsCode.AcsCodeId;
    //                         question.PlanOfActionSectionId = section.PlanOfActionSectionId;

    //                         section.PlanOfActionSectionQuestion.push(question);

    //                         // Set to not missing
    //                         acsCodeStatuses.codesById[acsCode.AcsCodeId].missing = false;
    //                     }
    //                 }
    //             }

    //             return acsCodeStatuses;
    //         }))

    // }

    getAcsCodesForSection(acsCodeStorage: AcsCodeStorage, section: PlanOfActionSectionEntity): AcsCodesStorageItem {

        switch (section.PlanOfActionSectionTypeId) {
            case PlanOfActionSectionTypeAllItems.AreaOfOperation:
                return acsCodeStorage.AreaOfOperationCodes[section.AcsAreaOfOperationId];
            case PlanOfActionSectionTypeAllItems.Task:
                return acsCodeStorage.TaskCodes[section.AcsAreaOfOperationTaskId];
        }

        return null;
    }



    getAcsCodesFromreference(acsReferenceInfo: AcsReferenceInfo, areaOfOperationId?: number, taskId?: number): AcsCodesStorageItem {
        if (!acsReferenceInfo) {
            console.log('No reference info');
            return { acsCodes: [], codesById: {} };
        }
        if (taskId) {
            return acsReferenceInfo.acsCodes?.TaskCodes[taskId];
        }
        if (areaOfOperationId) {
            return acsReferenceInfo.acsCodes?.AreaOfOperationCodes[areaOfOperationId];
        }

        return acsReferenceInfo.acsCodes;
    }

    getAcsCodes(acsTestId: number, areaOfOperationId?: number, taskId?: number): Observable<AcsCodesStorageItem> {
        return this.getAcsCodeStorage(acsTestId)
            .pipe(map(storage => {
                if (taskId) {
                    return storage?.TaskCodes[taskId];
                }
                if (areaOfOperationId) {
                    return storage?.AreaOfOperationCodes[areaOfOperationId];
                }

                return storage;
            }));
    }


    getAcsCodeStorage(acsTestId: number): Observable<AcsCodeStorage> {
        const cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsCode.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsAreaOfOperationTask.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsAreaOfOperation.ItemDetail.itemName));
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.AcsTest.ItemDetail.itemName));

        const key = `acsCodesLookup_${acsTestId}`;

        return this.dwCacheService.get(key, this.loadAcsCodes(acsTestId), null, cacheInvalidationTopics);
    }

    loadAcsCodes(acsTestId: number): Observable<AcsCodeStorage> {
        return AppMetaData.Queries.AcsCodesLookup.CreateQueryBuilder(this.dwMetaDataService)
            .pipe(mergeMap(query => {

                query.addFilterAnd(f => f.Equal(a => a.AcsTestAcsTestId, acsTestId));

                return this.dwOrmDataService.executeQuery(query)
                    .pipe(map(acsCodes => this.convertAcsCodesToStorage(acsCodes)));
            }));
    }

    /**Convert array into an optimized storage container for getting codes by Area of Operation and Task */
    private convertAcsCodesToStorage(acsCodes: AcsCodesLookupQueryEntity[]): AcsCodeStorage {
        const storage: AcsCodeStorage = {
            acsCodes: acsCodes,
            codesById: {},
            AreaOfOperationCodes: {},
            TaskCodes: {}
        };

        storage.codesById = flattenArray(acsCodes, 'AcsCodeId');

        storage.AreaOfOperationCodes = this.convertGroupCodes(acsCodes, 'AcsAreaOfOperationAcsAreaOfOperationId');
        storage.TaskCodes = this.convertGroupCodes(acsCodes, 'AcsAreaOfOperationTaskId');

        return storage;
    }

    private convertGroupCodes(acsCodes: AcsCodesLookupQueryEntity[], groupByField: string): AcsCodeGroup {
        const groupedCodes = this.groupByArray(acsCodes, groupByField);

        const codeGroup: AcsCodeGroup = {};

        for (const groupId in groupedCodes) {
            if (groupedCodes.hasOwnProperty(groupId)) {
                codeGroup[groupId] = {
                    acsCodes: groupedCodes[groupId],
                    codesById: flattenArray(groupedCodes[groupId], 'AcsCodeId')
                }
            }
        }

        return codeGroup;
    }


    groupByArray<T>(array: T[], fieldName?: string): { [prop: string]: T[] } {
        return array
            .reduce((hash, obj: T) => {
                if (obj[fieldName] === undefined) return hash;
                return Object.assign(hash, { [obj[fieldName]]: (hash[obj[fieldName]] || []).concat(obj) })
            }, {})
    }
}