import { Injectable } from "@angular/core";
import { PlanOfActionRepositoryService } from "./plan-of-action-repository.service";
import { DwModalButtonStandardConfigs, DwModalConfig, DwModalResult, DwModalService, DwModalSize } from "@devwareapps/devware-cap";
import { PlanOfActionEntity } from "../../../meta-data/app-meta-data.service";
import { Observable } from "rxjs";
import { GenerateSectionsModalComponent } from "../components/generate-sections-modal/generate-sections-modal.component";
import { map } from "rxjs/operators";
import { AcsSectionSelectionModalOptions } from "../models/acs-section-selection-modal-options.model";

@Injectable({ providedIn: 'root' })
export class PlanOfActionModalService {


    constructor(private planOfActionRepositoryService: PlanOfActionRepositoryService,
        private dwModalService: DwModalService

    ) {}

    
    showGenerateSectionsModal(planOfAction: PlanOfActionEntity, acsTestPortionId: number): Observable<DwModalResult<PlanOfActionEntity>> {
        let modalConfig = new DwModalConfig<AcsSectionSelectionModalOptions>();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.medium;

        modalConfig.component = GenerateSectionsModalComponent;

        modalConfig.title = 'Generate Sections';
        modalConfig.data = {
            planOfAction: planOfAction,
            acsTestPortionId: acsTestPortionId
        };
        
        return this.dwModalService.showModal<any,PlanOfActionEntity>(modalConfig);
                // const result = new DwActionResult();

                // if (result.cancelled) {
                //     result.cancelled = true;
                    
                //     return result;
                // }
                // const planOfAction = modalConfig.data;

                // if (planOfAction) {
                    
                //     // Try to patch the data itself so it will create the form array entries for sections & questions
                //     actionState.formApi.patchContextData(DwMdFormContextInfo.DATA_CONTEXT_NAME, planOfAction); 
                //     actionState.formApi.formGroup.patchValue(planOfAction);
                //     actionState.formApi.setFormData(planOfAction);

                //     // Old - wasn't working - might need to research why
                //     //actionState.formApi.patchFormData(modalResult.data);
                // }

                // return result;
           //}));
    }




}